import axios from 'axios';
import { useState, useEffect } from 'react';
import AdminPanel from './adminPanel';

const Deliveries = () => {
  const [usersByDeliveryDay, setUsersByDeliveryDay] = useState({});
  const [delslotTime, setDelslotTime] = useState('');

  useEffect(() => {
    document.title = "Users List";

    const fetchData = async () => {
      try {
        // Fetch users and meals data
        const [usersRes, mealsRes] = await Promise.all([
          axios.get('/api/auth/allusers'),
          axios.get('/api/auth/allmeals')
        ]);

        const usersData = usersRes.data;
        const mealsData = mealsRes.data;

        // Create a mapping of meal IDs to meal details
        const mealMap = mealsData.reduce((acc, meal) => {
          acc[meal._id] = meal;
         
          return acc;
        }, {});

        // Replace mealSelections IDs with the corresponding meal details
        const usersWithMealDetails = usersData.map(user => {
          return {
            ...user,
            mealSelections: user.mealSelections.map(mealId => mealMap[mealId])
          };
        });

        // Group users by deliveryDay
        const groupedUsers = usersWithMealDetails.reduce((acc, user) => {
          const day = user.deliveryDay;
          if (!acc[day]) {
            acc[day] = [];
          }
          acc[day].push(user);
          return acc;
        }, {});

        setUsersByDeliveryDay(groupedUsers);
        
      } catch (err) {
        console.error('Error fetching data:', err);
      }
    };

    fetchData();
  }, []);

  const DelSlotFunc = ({userSlot}) => {
       
        userSlot = parseInt(userSlot)    
         
    switch (userSlot) { // 8-10 am 
      case 1:
        return '8:00 - 10:00am'
      case 2:
        return  '10:00 - 12:00am'
      case 3:
        return  '12:00 - 6:00pm'
      case 4:
        return  '12:00 - 6:00pm'
      }

    }


  return (
     <div className='adminDash'>
       <h1>Deliveries</h1>
       <AdminPanel />
       
       <div className='adminMainContent '>
        <div className='dashboard'></div>
    <div>
      {Object.keys(usersByDeliveryDay).map(day => (
        <div key={day} className='wkdeliveryDay'>
          <h2> {day}</h2>
          {usersByDeliveryDay[day]
           .sort((a, b) =>
                parseInt(a.deliverTimeSlot)-(parseInt(b.deliverTimeSlot))
              )
          
          .map(user => (
            <div key={user._id} className='user'>
              <p><strong>{user.firstName} {user.lastName}</strong></p>
              <span><strong>Order:</strong></span>
              
             
              <ul>
          {/* Ensure mealSelections is an array and iterate through valid meals */}
          {Array.isArray(user.mealSelections) && user.mealSelections.length > 0 ? (
            user.mealSelections.map((meal, index) => {
              // Check if meal is defined
              if (!meal) {
               
                return null; // Skip undefined meals
              }

              

              
              return (
                <li key={meal._id}>
                 
                  <p> {meal.title}</p>
                </li>
              );
            })
          ) : (
            <p>No meals available</p>
          )}
        </ul>
              <div className='address'><strong>To:</strong>
              <ul>
                <li>{user.addressLine1}</li>
                <li>{user.addressLine2}</li>
                <li>{user.area}</li>
                <li>{user.town}</li>
                </ul></div>
                <div><strong>Delivery Slot:</strong> {<DelSlotFunc userSlot={user.deliverTimeSlot} />}</div>
                
            </div>
          ))}
        </div>
      ))}
    </div>
  </div>
    </div>
  );
};

export default Deliveries;
