import React, { useContext, useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import AdminPanel from './adminPanel';
import axios from 'axios';

const EditMeal = () => {
  const navigate = useNavigate();
    const prefs = ['family friendly','kid friendly','low carb','vegetarian','pescatarian','calorie smart','classics','guilt free','chicken','meat','gourmet']
    
    const [preferences, setPreferences ]= useState([]);
     const [selectedImage, setSelectedImage] = useState(null);
  const [currentImage, setCurrentImage] = useState(null);
  const [imgName, setImgName] = useState(null);
    const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');
    const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const mealid = queryParams.get('id');
  
   
  const[dataset, setDataset] = useState(
      {
        image: '',
        title: '',
        subtitle: '',
        desc: '',
        ingredients: [],
        allergens: '',
        utensils: [],
        energykj: '',
        energykcal: '',
        protein: '',
        fat: '',
        saturated: '',
        carbs: '',
        ofwhichsugars: '',
        salt: '',
        ins1title: '',
        ins2title: '',
        ins3title: '',
        ins4title: '',
        ins5title: '',
        ins6title: '',
        ins1: '',
        ins2: '',
        ins3: '',
        ins4: '',
        ins5: '',
        ins6: '',
        mealtype: '',
         classic: false
      }
    );

    useEffect(() => {
      document.title = "Add Meal"

      
    },[])

 const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    

      setDataset((prevData) => ({
        ...prevData,
        [name]: type === 'checkbox' ? checked : value
      }));
    
  };

  const handlepreferenceChange = (e, index) => {
      if(e.target.checked){
        setDataset((prevData) => 
        ({...prevData, mealtype:e.target.value }))
        setPreferences(prevState => [...prevState, e.target.value])
      }else{
        var filteredArray = preferences.filter(preferences => preferences !== e.target.value)
        setPreferences(prevState =>filteredArray)
      }
    }
    

const handleImageChange = (event) => {
    // setSelectedImage(event.target.files[0]);
    setImgName(event.target.files[0].name)
    setFile(event.target.files[0]);
    const { name, value } = event.target;
    setDataset((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    
    setCurrentImage(URL.createObjectURL(event.target.files[0]));
  };

 
  const handleSubmit = (e) => {
      e.preventDefault();
      handleSubmitImage(e)
 }

  const handleSubmitImage = async (e) => {
    e.preventDefault();
     const formData = new FormData();
     formData.append('image', file);
              
    try {
      const response = await axios.post('api/auth/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      );
      setImgName(response.data.filename);
      handleSubmitData(e);
      
      
      // alert('Image uploaded successfully');
    } catch (error) {
      console.error('Error uploading the image', error);
      alert('Error uploading the image');
    }
  };
  

  const handleSubmitData = async (e) => {
    e.preventDefault();

    try {
    
      const response = await axios.post(`api/auth/addMeal`,{
             
              title: dataset.title, 
              image:dataset.image,
              subtitle: dataset.subtitle,
              desc: dataset.desc,
              ingredients: dataset.ingredients,
              allergens: dataset.allergens,
              utensils: dataset.utensils,
              energykj: dataset.energykj,
              energykcal: dataset.energykcal,
              protein: dataset.protein,
              fat: dataset.fat,
              saturated: dataset.saturated,
              carbs: dataset.carbs,
              ofwhichsugars: dataset.ofwhichsugars,
              salt: dataset.salt,
              ins1title: dataset.ins1title,
              ins2title: dataset.ins2title,
              ins3title: dataset.ins3title,
              ins4title: dataset.ins4title,
              ins5title: dataset.ins5title,
              ins6title: dataset.ins6title,
              ins1: dataset.ins1,
              ins2: dataset.ins2,
              ins3: dataset.ins3,
              ins4: dataset.ins4,
              ins5: dataset.ins5,
              ins6: dataset.ins6,
              mealtype: preferences,
              classic: dataset.classic
      });
      
      navigate("/mealsList");
    } catch (error) {
      console.error('Error uploading data:', error);
    }
  };




  const MealTypes = () => prefs.map((category, index) => (
    <div key={index} className='prefcheck'>
      <label className={`preference ${category.replace(/ /g,'')}`}   htmlFor={category}>{category}</label> 
        <input type="checkbox" value={category}  id={category} name="mealType" onChange={handlepreferenceChange}  checked={preferences.includes(category)}  /> 
    </div>
  ));

  return (
    <>
    <div className='adminDash'>
      <h1>MealsList</h1>
       
       <AdminPanel />
       
       <div className='adminMainContent userForm'>
    <h1>Add Meal</h1>
    <form onSubmit={handleSubmit}>
      <fieldset className='row2'>
    <div className='image'>
         {currentImage && (
        <img src={currentImage} alt="Current Image" id="current-image" />
      )}
    </div>
    <h4>Change Image</h4>
    <input type="file" name='image' accept="image/*" onChange={handleImageChange} />
    </fieldset>
     <fieldset className='col2'>
    <div>
      <label>Meal Title</label>
      <input type='text' name='title' onChange={handleChange} value={dataset.title} />

      </div>
     <div>
      <label>Meal Subtitle</label>
      <input type='text' name='subtitle' value={dataset.subtitle}  onChange={handleChange} />
      </div>
    <div><label>Meal Description</label><br /><textarea name='desc'  type='text' value={dataset.desc}  onChange={handleChange}></textarea>
    <div><label>Classic meal</label><input type='checkbox' name='classic'  value={dataset.classic}  onChange={handleChange} /></div>
    </div>
    </fieldset>
    <fieldset>
     <div><label>Ingredients</label><br /><textarea name='ingredients'  type='text' value={dataset.ingredients}  onChange={handleChange}></textarea></div>
    <div><label>Allergens</label><br /><textarea  name='allergens' type='text' value={dataset.allergens}  onChange={handleChange}></textarea></div>
    </fieldset>
    <fieldset className='col1'>
    <div><label>Utensils</label><br /><textarea name='utensils'  type='text' value={dataset.utensils}  onChange={handleChange}></textarea></div>
    </fieldset>
     <fieldset className='col2'>
    <div><label>energykj</label><input name='energykj'  type='text' value={dataset.energykj}  onChange={handleChange}></input></div>
    <div><label>energykcal</label><input name='energykcal'  type='text' value={dataset.energykcal}  onChange={handleChange}></input></div>
    <div><label>protein</label><input  type='text' name='protein' value={dataset.protein}  onChange={handleChange}></input></div>
    <div><label>fat</label><input  type='text' name='fat' value={dataset.fat}  onChange={handleChange}></input></div>
    <div><label>saturatedfat</label><input name='saturated' type='text' value={dataset.saturated}  onChange={handleChange}></input></div>
    <div><label>carbohydrates</label><input name='carbs' type='text' value={dataset.carbs}  onChange={handleChange}></input></div>
    <div><label>Of which sugars</label><input name='ofwhichsugars'  type='text' value={dataset.ofwhichsugars}  onChange={handleChange}></input></div>
    <div><label>Salt</label><input  type='text' name='salt' value={dataset.salt}  onChange={handleChange}></input></div>
    </fieldset>
    <fieldset className='col3'>
    <div><label>Step 1 Instruction Title</label><br /><input name='ins1title'  type='text' value={dataset.ins1title}  onChange={handleChange}></input></div>
    <div><label>Step 1 Instruction</label><br /><textarea name='ins1'  type='text' value={dataset.ins1}  onChange={handleChange}></textarea></div>
    <div><label>Step 2 Instruction Title</label><br /><input name='ins2title'  type='text' value={dataset.ins2title}  onChange={handleChange}></input></div>
    <div><label>Step 2 Instruction</label><br /><textarea name='ins2'  type='text' value={dataset.ins2}  onChange={handleChange}></textarea></div>
    <div><label>Step 3 Instruction Title</label><br /><input name='ins3title'  type='text' value={dataset.ins3title}  onChange={handleChange}></input></div>
    <div><label>Step 3 Instruction</label><br /><textarea name='ins3'  type='text' value={dataset.ins3}  onChange={handleChange}></textarea></div>
    <div><label>Step 4 Instruction Title</label><br /><input name='ins4title'  type='text' value={dataset.ins4title}  onChange={handleChange}></input></div>
    <div><label>Step 4 Instruction</label><br /><textarea name='ins4'  type='text' value={dataset.ins4}  onChange={handleChange}></textarea></div>
    <div><label>Step 5 Instruction Title</label><br /><input name='ins5title'  type='text' value={dataset.ins5title}  onChange={handleChange}></input></div>
    <div><label>Step 5 Instruction</label><br /><textarea name='ins5'  type='text' value={dataset.ins5}  onChange={handleChange}></textarea></div>
    <div><label>Step 6 Instruction Title</label><br /><input name='ins6title'  type='text' value={dataset.ins6title}  onChange={handleChange}></input></div>
    <div><label>Step 6 Instruction</label><br /><textarea name='ins6'  type='text' value={dataset.ins6}  onChange={handleChange}></textarea></div>
   </fieldset>
    <fieldset className='col4'>
   <div><h3>Meal Types</h3><MealTypes /></div>
   </fieldset>
    <div className='buttonSection'>
   <button type='submit'>Submit Changes</button>
    <Link to="/mealsList"> <button>Cancel</button></Link>
   
   </div>
   </form>
    </div>
    </div>
    </>
  )
}

export default EditMeal