import React, { useContext, useState, useEffect } from 'react';

import { AuthContext } from '../context/AuthContext';
import axios from 'axios';
import AdminPanel from './adminPanel';

const AdminDash = () => {
  
   const [users, setUsers] = useState([])
   const [usersLength, setUsersLength] = useState('')
   const [totalMealSelections, setTotalMealSelections] = useState(0);
 
   const [counts, setCounts] = useState({});
const [today, setToday] = useState('');
  const [todayCount, setTodayCount] = useState(0);

   useEffect(() => {
    
  
       const res =  axios.get(`/api/auth/allusers`)
        .then(res => {
        const usersData = res.data;
        setUsers(usersData);
        setUsersLength(usersData.length);

        // Calculate total meal selections
        const totalMeals = usersData.reduce((acc, user) => acc + (user.mealSelections ? user.mealSelections.length : 0), 0);
        setTotalMealSelections(totalMeals);
      })
      .catch(err => {
        console.error('Error fetching users:', err);
      });
  }, []);


  useEffect(() => {
    const countTowns = (arr) => {
     
      const countsMap = new Map();
      arr.forEach(obj => {
        const town = obj.town;
        countsMap.set(town, (countsMap.get(town) || 0) + 1);
      });
      const countsObject = Object.fromEntries(countsMap);
      setCounts(countsObject);
    };

    countTowns(users);
  
  }, [users]);

  

   useEffect(() => {
    const getDayOfWeek = () => {
      const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      const today = new Date();
      const dayIndex = today.getDay();
      return daysOfWeek[dayIndex];
    };

    const countTodayDeliveries = (arr, today) => {
      return arr.reduce((count, obj) => {
        return obj.deliveryDay === today ? count + 1 : count;
      }, 0);
    };

    const todayDay = getDayOfWeek();
    setToday(todayDay);

    const count = countTodayDeliveries(users, todayDay);
    setTodayCount(count);
  }, [users]);



  return (
    <>
   
    <div className='adminDash'>
       <h1>Admin Dashboard</h1>
       <AdminPanel />
       
       <div className='adminMainContent '>
        <div className='dashboard'>
        <div className='noOfUsers dashpanels'><p>Current number of Users:<br/><span>{usersLength}</span></p></div>
        <div className='noOfMeals dashpanels'><p>Number of meals on order:<br/><span>{totalMealSelections}</span></p></div>
        <div className='noOfOrders dashpanels'><p>Number of orders<br/><span>0</span></p></div>
       
        <div className='deliveriesToday dashpanels'><p>Deliveries today:<br/><span> {todayCount}</span></p></div>
       </div>
         <div className='townPanel'>
      <h2>Cities currently delivering to</h2>
      <ul>
        {Object.entries(counts).map(([town, count]) => (
          <li key={town}>
           <strong> {town}:</strong> Number of deliveries: {count}
          </li>
        ))}
      </ul>
    </div>

     
       
       
       </div>

      

    </div>
    </>
  )
}

export default AdminDash