import React from 'react'
import { useEffect, useRef, useState } from 'react'
import '../styles/home.css'




const Home = () => {

  const [panelActive, setPanelActive] = useState(false)
  const ToggleClass = (e) => {
    
    const lilist = document.querySelectorAll(".accordion");
    let panelList = document.querySelectorAll(".panel");
     lilist.forEach(element => { element.classList.remove("active"); })
     e.currentTarget.classList.toggle("active")
     panelList.forEach(element => { element.style.maxHeight = null; element.style.padding = "0px" })
     let panel = e.currentTarget.nextElementSibling;
      panel.style.maxHeight = panel.scrollHeight + "px";
      panel.style.padding = "18px"
  }


  useEffect(() => {
   
    const buttonSection = document.querySelector(".moremeals .midsection")
    const articles = document.querySelectorAll("article")
    const elementsToExpand = document.querySelectorAll(".svgSection")
    const texttoAnim = document.querySelectorAll(".animText")
    const titlestoAnim = document.querySelectorAll(".headertoAnim")
    const instaImgs = document.querySelectorAll(".instaImg")
    const midadgreens = document.querySelector(".midadgreen")
    const whys = document.querySelector(".why")
    const imageList = document.querySelector('.beHealthy')
    const scrollers = document.querySelectorAll(".scroller");

     let articlesAnimObserver = new IntersectionObserver(entries => {
        let counter = 0
        entries.forEach(entry => {

            if (entry.intersectionRatio > 0) {

                entry.target.style.animation = 'moremeals 2s ease forwards';
                
                counter = counter + .2
                buttonSection.style.opacity = 1
            }

        })

    }, {
        threshold: 1,
        rootMargin: "0px 0px -100px 0px"
    }
    )

    articles.forEach(element => {
        articlesAnimObserver.observe(element)
    })
    
    let expansionObserver = new IntersectionObserver((entries) => {

        entries.forEach(entry => {
            const [{ isIntersecting }] = entries
            // if (entry.intersectionRatio > 0) {

            if (isIntersecting) {
                entry.target.style.opacity = 1
                if (entry.target.classList.contains('arc')) {
                    
                    entry.target.innerHTML = ' <object type="image/svg+xml" data="/images/rightarrowP.svg"></object>'
                }
                expansionObserver.unobserve(entry.target)
            }


            // }

        })

    }, { rootMargin: '0px 0px 0% 0px', threshold: .9 })


    elementsToExpand.forEach(element => {
        expansionObserver.observe(element)
    })


     let textAnimObserver = new IntersectionObserver(entries => {

        entries.forEach(entry => {

            if (entry.intersectionRatio > 0) {

                entry.target.style.animation = 'heroSwipe .5s ease forwards';

            }

        })

    }, {
        threshold: .9
    }
    )
    texttoAnim.forEach(element => {
        textAnimObserver.observe(element)
    })


    let headAnimObserver = new IntersectionObserver(entries => {

        entries.forEach(entry => {

            if (entry.intersectionRatio > 0) {
                entry.target.classList.add("titleAnim")
            }

        })

    }, {
        rootMargin: "0px 0px -150px 0px"
    }
    )



    titlestoAnim.forEach(element => {
        headAnimObserver.observe(element)
    })


    let midasObserver = new IntersectionObserver(entries => {

        entries.forEach(entry => {

            if (entry.intersectionRatio > 0) {

                entry.target.style.transform = 'translateX(0%)';
                let midasContent = document.querySelectorAll(".midasContent")
                for (let i = 0; i < midasContent.length; i++) {
                    midasContent[i].classList.add("activate")

                }

            }

        })

    }, {
        rootMargin: "0px 0px -100px 0px"
    }
    )



    midasObserver.observe(midadgreens)

    
     let whysObserver = new IntersectionObserver(entries => {

        entries.forEach(entry => {

            if (entry.intersectionRatio > 0) {

                entry.target.style.transform = 'translateX(0%)';
                let whysContent = document.querySelectorAll(".whyContent")
                for (let i = 0; i < whysContent.length; i++) {
                    whysContent[i].classList.add("activate")

                }

            }

        })

    }, {
       
        rootMargin: "0px 0px -100px 0px"
    }
    )



    whysObserver.observe(whys)
    

    let pictAnimObserver = new IntersectionObserver(entries => {

        entries.forEach(entry => {

            if (entry.intersectionRatio > 0) {

                entry.target.style.opacity = '1';

            }

        })

    }, {
        rootMargin: "0px 0px -150px 0px"
    }
    )



    pictAnimObserver.observe(imageList)

    let instaImgsAnimObserver = new IntersectionObserver(entries => {
        let counter = 0
        entries.forEach(entry => {


            if (entry.intersectionRatio > 0) {

                entry.target.style.animation = 'instaResize 2s ease forwards';
                entry.target.style.animationDelay = counter + 's'
                counter = counter + .2
            }

        })

    }, {
        
        rootMargin: "0px 0px -300px 0px"
    }
    )



    instaImgs.forEach(element => {
        instaImgsAnimObserver.observe(element)
    })


    // If a user hasn't opted in for recuded motion, then we add the animation
    if (!window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
        addAnimation();
    }

    function addAnimation() {
        scrollers.forEach((scroller) => {
            // add data-animated="true" to every `.scroller` on the page
            scroller.setAttribute("data-animated", true);

            // Make an array from the elements within `.scroller-inner`
            const scrollerInner = scroller.querySelector(".scroller__inner");
            const scrollerContent = Array.from(scrollerInner.children);

            // For each item in the array, clone it
            // add aria-hidden to it
            // add it into the `.scroller-inner`
            scrollerContent.forEach((item) => {
                const duplicatedItem = item.cloneNode(true);
                duplicatedItem.setAttribute("aria-hidden", true);
                scrollerInner.appendChild(duplicatedItem);
            });
        });
    }

  

  }, [])


  return (
  <>
  <div className="mainContainer homeContainer">
     <section className="hero">
        
         <div>
            <p className="cook">Cookit.</p>
            <button className="getStartedBut">Get started</button>
        </div>
    </section>

    <section className="moremeals">
        <div className="overflowSection">
          
            <article>

                <img src='/images/26.jpg' alt="" />

                <h3>Penne Alla Norma</h3>
                <p>Roasted chicken, tomatoes, parmesan crisps, shaved parmesan, shredded kale, chopped romaine, lime
                    squeeze, caesar</p>
            </article>
            <article>

                <img src="/images/COOKITFEBc.jpg" alt="" />

                <h3>Creamy Minced Beef</h3>
                <p>Roasted chicken, avocado, tomatoes, red onions, shredded cabbage, tortilla chips, spring mix, chopped
                    romaine, lime
                    squeeze, lime cilantro jalapeño vinaigrette</p>
            </article>
            <article>

                <img src="/images/COOKITFEB.jpg" alt="" />

                <h3>Shrimp Orzo Pasta</h3>
                <p>Blackened chicken, pickled onions, tomatoes, raw carrots, cilantro, blue cheese, za’atar breadcrumbs,
                    shredded kale,
                    chopped romaine, sweetgreen hot sauce, caesar</p>
            </article>

          
        </div>
        <div className="midsection" >
            <button className="getStartedBut">See all our dishes</button>
            <p className="linkText">View all the dishes available over the next month</p>
        </div>
       
    </section>


    <section className="how" id="hwwrks">

        <div className="howIcons">
            <h2 className="headertoAnim">How it works</h2>
            <p className="subheading headertoAnim">4 easy steps</p>

            <div className="addPeople svgSection">
                <object className="newSVG" type="image/svg+xml" data="/images/add.svg"></object>
           </div>
            <div className="addpeopleP animText">
                <div className="span">1</div>
                <p> Select how many people you are cooking for and how many times a week you would like a
                    delivery</p>
            </div>
            <div className="curveOne svgSection arc"></div>
           
            <div className="meals svgSection">
                <object className="newSVG" type="image/svg+xml" data="/images/meals.svg"></object>
            </div>
            <div className="mealsP animText">
                <div className="span">2</div>
                <p>Select your meals from 20 recipes</p>
            </div>
            <div className="curveTwo svgSection arc"></div>
            <div className="truck svgSection">
                <object className="newSVG" type="image/svg+xml" data="/images/deliver.svg"></object>
            </div>
            <div className="truckP animText">
                <div className="span">3</div>
                <p>Receive your order when you want it</p>
            </div>
            <div className="curveThree svgSection arc"></div>
            <div className="cookit svgSection">
              <object className="newSVG" type="image/svg+xml" data="/images/cook.svg"></object>
            </div>
            <div className="cookitP animText">
                <div className="span">4</div>
                <p>Cook It!</p>
            </div>
        </div>
       </section>


       <section className="midadgreen">
        <div className="midasContent">
            <p className="preamble">Ipsum dolores est</p>
            <h2>Lorem Impsom Est</h2>
            <p>At our heart, we believe that the act of cooking isn't just about putting food on the table—it's a
profound expression of care, creativity, and connection. </p><p>We're more than just a meal kit service;
we're a community of culinary enthusiasts dedicated to bringing the joy of home cooking back
into your life. With carefully curated ingredients and easy-to-follow recipe cards, we empower you
to create delicious, wholesome meals in the comfort of your own kitchen.</p><p> Beyond the
convenience, there's something truly transformative about preparing a meal from scratch—it
nourishes not only your body but also your soul. By prioritizing quality ingredients and promoting
the joys of cooking, we're not just building a business; we're fostering a movement towards
healthier, happier lifestyles.</p><p> Join us in celebrating the art of home cooking and let's savor every
moment shared around the dinner table.

            </p>
        </div>
        <img src="/images/chickenpangang.png" alt="" className="midasContent" />
    </section>
    <section className="why">
        

        <img src="/images/34.jpg" alt="" className="whyContent" />
        <div className="whyContent whytext ">
            <h2>Why Cookit?</h2>
            <div>
                <h3>Quick & Easy Home Cooking</h3>
                <p>No need to plan, shop or prepare your
                    ingredients. All pre-prepareed and pre-measured ingredients and easy-to-follow recipes.</p>
            </div>
            <div>
                <h3>Convenience & Flexibitlity</h3>
                <p>20 new dishes to choose from every week. No commitment: Skip, pause or cancel at anytime.
                    Make unlimited changes to your plan.</p>
            </div>

            <div>
                <h3>Healthy & Nutritious</h3>
                <p>Healthy balanced meals for the whole family.
                    Calorie managed dishes and popular dietary preferences for you to choose from.</p>
            </div>
            <div>
                <h3>Quality & Freshness</h3>
                <p>Fresh high-quality ingredients sourced from our trusted suppliers. Top class kitchen facility,
                    and 100% Biodegradable plastic and fully recyclable materials.</p>
            </div>
        </div>

    </section>

    <section className="beHealthy">
        <div className="behealthysection">
            <h2 className="headertoAnim">Be healthy, be inspired, be sustainable</h2>
            <p className="subheading headertoAnim">Choose from over 80 recipes a month, choose to filter by different
                selections and set your favourites</p>
        </div>
        <div className="scroller">
            <div className="scroller__inner">

                <img src="/images/IMG_1846.jpg" alt="" />
                <img src="/images/IMG_1893.jpg" alt="" />
                <img src="/images/IMG_2306.jpg" alt="" />
                <img src="/images/IMG_2876.jpg" alt="" />
                <img src="/images/IMG_2901.jpg" alt="" />
                <img src="/images/IMG_2929.jpg" alt="" />
                <img src="/images/IMG_2935.jpg" alt="" />
            </div>
            
        </div>
        <div className="midsection">
                <button className="getStartedBut">See all our dishes</button>
                <p className="linkText">View all the dishes available over the next month</p>
            </div>
    </section>
    <section className="instasection">

        <img className="instaicon" src="/images/noun-instagram-6161542.png" alt="" />
        <h2 className="headertoAnim">#cookitae</h2>
        <p className="subheading instasubheading headertoAnim">Follow us on Instagram @cookitae</p>

        <div className="instaImages">
            <img src="/images/hannah-busing-5we-PtvuCyE-unsplash.jpg" alt="" className="instaImg" />
            <img src="/images/andrew-valdivia-6zMksDPi6Z4-unsplash.jpg" alt="" className="instaImg" />
            <img src="/images/le-creuset-LJ8OnqaYUqw-unsplash.jpg" alt="" className="instaImg" />
            <img src="/images/jonathan-borba-uB7q7aipU2o-unsplash.jpg" className="instaImg" alt="" />

        </div>

    </section>
    <section className="faqsection">
        <div className="faqheader">
            <h2 className="headertoAnim">Questions?</h2>

        </div>
        <div className="faqs">
            <ul>
                <li className="accordion" onClick={ToggleClass}>how do I select my meals ?</li>
                <div className="panel">
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                        labore
                        et
                        dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                        ex
                        ea
                        commodo
                        consequat.</p>
                </div>

                <li className="accordion" onClick={ToggleClass}>Is it possible to skip or cancel a delivery ?</li>
                <div className="panel">
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                        labore
                        et
                        dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                        ex
                        ea
                        commodo
                        consequat.</p>
                </div>

                <li className="accordion" onClick={ToggleClass}>Is there a minimum number of portions I have to add to my box ?</li>
                <div className="panel">
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                        labore et
                        dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                        ex ea
                        commodo
                        consequat.</p>
                </div>
                <li className="accordion" onClick={ToggleClass}>Can I pause my subscription? How does it work ?</li>
                <div className="panel">
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                        labore
                        et
                        dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                        ex
                        ea
                        commodo
                        consequat.</p>
                </div>

                <li className="accordion" onClick={ToggleClass}>Do you offer vegetarian and vegan meals ?</li>
                <div className="panel">
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                        labore
                        et
                        dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                        ex
                        ea
                        commodo
                        consequat.</p>
                </div>

                <li className="accordion" onClick={ToggleClass}>Are your recipes difficult ?</li>
                <div className="panel">
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                        labore et
                        dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                        ex ea
                        commodo
                        consequat.</p>
                </div>
            </ul>
            <ul>
                <li className="accordion" onClick={ToggleClass}>Whats included in the box?</li>
                <div className="panel">
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                        labore
                        et
                        dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                        ex
                        ea
                        commodo
                        consequat.</p>
                </div>

                <li className="accordion" onClick={ToggleClass}>How quickly should I cook the recipes ?</li>
                <div className="panel">
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                        labore
                        et
                        dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                        ex
                        ea
                        commodo
                        consequat.</p>
                </div>

                <li className="accordion" onClick={ToggleClass}>How long does it take to prepare one of your recipes ?</li>
                <div className="panel">
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                        labore et
                        dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                        ex ea
                        commodo
                        consequat.</p>
                </div>
                <li className="accordion" onClick={ToggleClass}>How do I skip a delivery or change its date or address ?</li>
                <div className="panel">
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                        labore
                        et
                        dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                        ex
                        ea
                        commodo
                        consequat.
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                        labore
                        et
                        dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                        ex
                        ea
                        commodo
                        consequat.</p>
                </div>

                <li className="accordion" onClick={ToggleClass}>How many meals do you offer per week ?</li>
                <div className="panel">
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                        labore
                        et
                        dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                        ex
                        ea
                        commodo
                        consequat.</p>
                </div>

                <li className="accordion" onClick={ToggleClass}>Do you offer meals for 1 person ?</li>
                <div className="panel">
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                        labore et
                        dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                        ex ea
                        commodo
                        consequat.</p>
                </div>

            </ul>
        </div>
    </section>
    </div>
  </>
  )
};

export default Home;
