import React from 'react';
import { useNavigate } from 'react-router-dom';

const LinkWithScroll = ({ to, anchorId, children }) => {
  const navigate = useNavigate();

  const handleClick = (event) => {
    event.preventDefault();
    navigate(to, { replace: false });
    setTimeout(() => {
      document.getElementById(anchorId)?.scrollIntoView({ behavior: 'smooth' });
    }, 0);
  };

  return (
    <a href={`${to}#${anchorId}`} onClick={handleClick}>
      {children}
    </a>
  );
};

export default LinkWithScroll;