import React from 'react'
import { AuthContext } from '../context/AuthContext';
import { Link, useLocation } from 'react-router-dom';  
import { useContext, useState, useEffect } from 'react';
import LogoutButton from './LogoutButton';
import LinkWithScroll from './linkWithScroll';
import '../styles/nav.css';



function Nav() {


  const { authState } = useContext(AuthContext);
  const burgerCheck = document.querySelector(".burgerCheck");

const location = useLocation();

  useEffect(() => {
    resize()
      window.scrollTo(0, 0);
  }, [location]);
    

      

  const hamburgerListener= () => {
    
          if(burgerCheck){
            burgerCheck.checked = !burgerCheck.checked
          }
          
        }

        const resize = () => {
          if(burgerCheck){
             burgerCheck.checked = false
          }
          
        }

        const showNav = () => {
        const nvPanle =  document.querySelector('.navPanel');
        nvPanle.classList.toggle('open');
        }
    
    window.onresize=resize
    window.onload=resize
   




  return (
     <nav>
              {authState.isAuthenticated ? (
                <>
                <div className="hamburgerSection inslet">
                    {/* <label className="hamburgerMenu">
                      <input className="burgerCheck" type="checkbox" />
                    </label>
                     <aside className='aside'>
                      <div className='sidebar'>
                    <ul>
                         
            <li><Link to="/dashboard">Plan Settings</Link></li>
            <li><Link to="/mymeals">Meals List</Link></li>
            <li><Link to="/mypreferences">Preferences</Link></li>
            <li><Link to="/myplan">Plan Info</Link></li>
            <li><Link to="/mydelivery">Delivery Info</Link></li>
            <li><Link to="/cancelplan">Cancel Plan</Link></li>
            <li><Link to="/accountinfo">Account Info</Link></li>
            <li><Link to="/paymentmethods">Payment Methods</Link></li>
            <li><Link to="/offers">Offers</Link></li>
            <li><Link to="/orderhistory">Order History</Link></li>
            <li><Link to={'/contact'}>Contact Us</Link></li>
                                            <LogoutButton />
                    </ul>
                  
                </div>
                 </aside> */}
                 <aside >
                   <i className="bi bi-person-circle" onClick={showNav}></i>
                   <div className='navPanel'>
                       <ul>
                         
            <li><Link to="/dashboard" onClick={showNav}><i className="bi bi-house"></i>Dashboard</Link></li>
            <li><Link to="/mymeals" onClick={showNav}><i className="bi bi-card-checklist"></i>Meals List</Link></li>
            <li><Link to="/mypreferences" onClick={showNav}><i className="bi bi-gear"></i>Preferences</Link></li>
            <li><Link to="/myplan" onClick={showNav}><i className="bi bi-info-square"></i>Plan Info</Link></li>
            <li><Link to="/mydelivery" onClick={showNav}><i className="bi bi-truck"></i>Delivery Info</Link></li>
            <li><Link to="/cancelplan" onClick={showNav}><i className="bi bi-x-square"></i>Cancel Plan</Link></li>
            <li><Link to="/accountinfo" onClick={showNav}><i className="bi bi-person-lines-fill"></i>Account Info</Link></li>
            <li><Link to="/paymentmethods" onClick={showNav}><i className="bi bi-credit-card"></i>Payment Methods</Link></li>
            <li><Link to="/offers" onClick={showNav}><i className="bi bi-megaphone"></i>Offers</Link></li>
            <li><Link to="/orderhistory" onClick={showNav}><i className="bi bi-hourglass"></i>Order History</Link></li>
            <li><Link to={'/contact'} onClick={showNav}><i className="bi bi-headphones"></i>Contact Us</Link></li>
                                            <LogoutButton />
                    </ul>
                   </div>
                     </aside>
                 </div>
                 <div className='logoSection lgin'>
                 <Link to={'/'}><img src="/images/logo.png" alt="logo" /></Link>
                 </div>
               
                </>
            ) : (
              <>
              
              <div className="hamburgerSection">
                  <label className="hamburgerMenu">
                      <input className="burgerCheck" type="checkbox" />
                   </label>
                  <aside className='aside'>
                      <div className='sidebar'>
                    <ul>
              
                 <li onClick={hamburgerListener}><object className="newSVG" type="image/svg+xml" data="/images/icons/add.svg"></object><Link to={'/boxes'}>The boxes</Link></li>
                  <li onClick={hamburgerListener}><object className="newSVG" type="image/svg+xml" data="/images/icons/cook.svg"></object><Link to={'/themeals'}>The meals</Link></li>
                   <li><object className="newSVG" type="image/svg+xml" data="/images/icons/deliver.svg"></object><LinkWithScroll to="/home" anchorId="hwwrks">How it works</LinkWithScroll></li>
                    <li onClick={hamburgerListener}><i className="bi bi-bullseye"></i><Link to={'/mission'}>Our Mission</Link></li>
                     <li onClick={hamburgerListener}><i className="bi bi-headphones"></i><Link to={'/contact'}>Contact Us</Link></li>

                  <LogoutButton />
                  </ul>
                  </div>
                  </aside>
                  <aside className='topbar'>
                    <ul>
                  {/* <li onClick={hamburgerListener}><Link to={'/home#hwwrks'}>How it works</Link></li> */}
                
                 <li onClick={hamburgerListener}><Link to={'/boxes'}>The boxes</Link></li>
                  <li onClick={hamburgerListener}><Link to={'/themeals'}>The meals</Link></li>
                  <li><LinkWithScroll to="/home" anchorId="hwwrks">How it works</LinkWithScroll></li>
                  <li onClick={hamburgerListener}><Link to={'/mission'}>Our Mission</Link></li>
                  <li onClick={hamburgerListener}><Link to={'/contact'}>Contact Us</Link></li>
                  </ul>
                 </aside>
                 </div>
                  <div className='logoSection'>
               <Link to={'/'}> <img src="/images/logo.png" alt="logo" /></Link>
               </div>
               <div className="topbut">
               <LogoutButton  />
                    </div>
                    </>
            )}
    </nav>
  )
}

export default Nav