import React, { useContext, useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';


const AdminPanel = () => {
  return (
    <div className='linksPanel'>
        
        <dl>
            <dt><Link to="/adminDash">Home</Link></dt>
            <dt value="Meals">Meals</dt>
              <dd><Link to="/mealsList">Meals List</Link></dd>
              <dd><Link to="/addMeal">Add New meal</Link></dd>
              <dd><Link to="/setMenu">Set menus</Link></dd>
            <dt value="Users">Users</dt>
             <dd><Link to="/usersList">Users List</Link></dd>
              <dd><Link to="/adduser">Add New Admin</Link></dd>
            <dt value="Delivery">Deliveris</dt>
              <dd><Link to="/deliveries">Deliveries</Link></dd>
                {/* <dt value="Siteoptions">Site Options</dt> */}
        </dl>    
       
       </div>
  )
}

export default AdminPanel
