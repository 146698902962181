import React, { useEffect, useState,useRef, useContext } from 'react';
import axios from "axios" 
import { AuthContext } from '../context/AuthContext';
import UserPanel from "./userPanel";

const Myboxes = (props) => {
   const [updateMessage, setUpdateMessage] = useState('')
const [loading, setLoading] = useState(false);

   const { authState } = useContext(AuthContext); 
  const [people, setPeople] = useState(authState.user.noPeople.toString())
  const [meals, setMeals] = useState(authState.user.noMeals.toString())
  const [total, setTotal] = useState(authState.user.total)
  const [pricepermeal, setPricepermeal] = useState(50)
  const [gourmetPrice, setGourmetPrice] = useState(10)
  let totalPrice = (people*meals)*pricepermeal
const userId= authState.user._id

useEffect(() => {
  document.title = 'My Plan'

  }, [])

const dialogRef = useRef();
const toggleDialog = async function(id) {

  if(!dialogRef.current){
    return
  } 
  dialogRef.current.hasAttribute("open") ? dialogRef.current.classList.add("closing") : dialogRef.current.classList.remove("closing");
  dialogRef.current.hasAttribute("open") ?  document.body.classList.remove("hideOverflow") : document.body.classList.add("hideOverflow");
   dialogRef.current.hasAttribute("open") ? dialogRef.current.close()  : dialogRef.current.showModal();

   if(dialogRef.current.hasAttribute("open")){
    

  }

}


const handlePeopleChange = e => {
setPeople(e.target.value)

}

const handleMealsChange= e => {
setMeals(e.target.value)

switch (e.target.value){
  case '3': 
    setPricepermeal(prevState => (50));
    break;
  case '4':
   setPricepermeal(prevState => (40));
    break;
  case '5':
    setPricepermeal(prevState => (30));
    break;
  default:
    setPricepermeal(prevState => (50));
}

}



const handleClick = async(e) => {
   e.preventDefault();
     toggleDialog();
  setLoading(true);
     try {                         
      const response = await axios.put(`/api/auth/updateUserPlan/${userId}`, {
        noPeople:people,
        noMeals:meals,
        total:total
      })
       .then(response=>{ 

        setUpdateMessage(response.data.message)
         setLoading(false)
      })
      
       
         }catch(error){
        console.error(`Error updating meal selections:`, error);
    
    }
     
       }

      
  return (
    <>

    <div className="dashboardContainer">
          
           <div className="userContainer">
         
       
       <div className="userData"> 
  <div className="boxes wrapper">
  <div className="container ">
  


  <h1>Boxes</h1>
  

  <h2>Select your box</h2>
  
    <div className="boxesPeople">
      <h3>How many people are you cooking for?</h3>
        <div className="radioGroup">
        <input type="radio" value="2"  name="people" id="noofpeopletwo" onChange={handlePeopleChange}  checked={people==="2"}  /><label htmlFor="noofpeopletwo">1/2</label>
        <input type="radio" value="3"  name="people" id="noofpeoplethree" onChange={handlePeopleChange} checked={people==="3"}/><label htmlFor="noofpeoplethree">3</label> 
        <input type="radio" value="4"  name="people"  id="noofpeoplefour" onChange={handlePeopleChange} checked={people==="4"}/><label htmlFor="noofpeoplefour" >4</label> 
        </div>
       </div>
        <div className="boxesPeople">

        <h3>How many recipes per week? </h3>
    
      
        <div className="radioGroup">
        <input type="radio" value="3" className="setMeals" name="nmeals" id="noofMealsthree" onChange={handleMealsChange} checked={meals==="3"} /><label htmlFor="noofMealsthree">3</label>
       <input type="radio" value="4" className="setMeals" name="nmeals" id="noofMealsfour" onChange={handleMealsChange} checked={meals==="4"}  /><label htmlFor="noofMealsfour">4</label>
       <input type="radio" value="5" className="setMeals" name="nmeals" id="noofMealsfive" onChange={handleMealsChange} checked={meals==="5"}  /><label htmlFor="noofMealsfive">5</label>
        </div>
        
        </div>
         <div className="mealsData"><p>You can cook <span>{people*meals}</span> servings per week.</p>
        <p>You will get a box with <span>{meals}</span> servings for <span>{people}</span> people</p></div>
       
       
       
        <div className="boxesData">
        
            <div className="boxesPrice">
              <p>Price per serving: {pricepermeal}AED</p>
              <hr></hr>
              <p>{people*meals} Servings <span>{people*meals*pricepermeal}AED</span></p>
              <p>Delivery <span>Free</span></p>
              <hr></hr>
              <p className="totalPrice">Total Price <span>{totalPrice}AED*</span></p>
              <p className="warning">*Selecting gourmet meals will incur an extra {gourmetPrice}AED</p>
            </div>
        </div>
            <div className="saveButtons">
              <button className="nextButton" onClick={handleClick}>
                Save
              </button>
            </div>
         </div>
        </div>
      </div>
  </div>
</div>

 <dialog className="filter"  ref={dialogRef} id="PaymentRef">
  <div className="modalTop"><span>Processing payment</span><button className="close" onClick={toggleDialog}>X</button></div>
<div className="modalBody"> 
           <p>Update Meals</p>
              {loading ? <img src="../images/icons/loading.gif" alt="loading" className="loading" /> : <p>{updateMessage}</p>}    
            </div>

             
            </dialog>
   </>
  )
}

export default Myboxes
