
import { Link, useNavigate } from 'react-router-dom';   

const Footer = () => {
  return (
  <>
    <footer>
        <div className="footerSection">
            <div className="leftSection">
                <h3>About Cook it</h3>
                <ul>
                    <li><Link to={'/howitworks'}>How it works</Link></li>
                    <li><Link to={'/boxes'}>The boxes</Link></li>
                     <li><Link to={'/themeals'}>The meals</Link></li>
                    {/*<li>Our desserts</li> */}
                    <li>Privacy policy</li>
                    <li>About us</li>
                </ul>
            </div>
            <div className="rightSection">
                <h3>Contact us</h3>
                <ul>
                    <li><a href="mailto:cookit@cookit.me">cookit@cookit.me</a></li>
                    <li><Link to="tel:041234567">04 123 45 67</Link></li>
                </ul>
            </div>
            {/* <div className="newsletter">
                <p>Subscribe to our newsletter</p>

                <input placeholder="Enter your email address" type="text" />
                <button>Subscribe</button>
            </div> */}
        </div>
    </footer>

    </>
  )
}


export default Footer