import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import axios from 'axios';

const SiteOptions = () => {
  return (
    <div>Site Options</div>
  )
}

export default SiteOptions