import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Modal from './modal';
import WeeklyDateRanges from "../components/weeklydaterange";




const Meals = ({formData, setFormData, inRegister, setInRegister} ) => {
  const [MealsList, setMealsList] = useState([]);
  const [mealsCount, setmealsCount] = useState(parseInt(formData.mealSelections.length));
  const [keys, setKeys]= useState(JSON.parse(localStorage.getItem('imgSrcs')) || [] )
 const [imgSrc, setImgSrc] = useState('');
 const [storedImgSrcs, setStoredImgSrcs] = useState([]);
    const [newImgSrc, setNewImgSrc] = useState('');
 let [open, setOpen] = useState(false)
  const [smallimgs, setSmallimgs]= useState(formData.smImages)
  const [mealSelection, setMealSelection] = useState('');
     const navigate = useNavigate();
     const meals = formData.mealSelections;
    //  console.log(formData.mealSelections)
     const [nmeals, setNmeals] = useState(parseInt(formData.nmeals))
     const [noMeals, setNoMeals] = useState(parseInt(formData.nmeals))
     const preferences = formData.preferences;
     const [index, setIndex] = useState(0);
   const [filteredMealsList, setFilteredMealsList ] = useState([])
   const [selectedFilters, setSelectedFilters] = useState([]);
const filters = ['family friendly','kid friendly','low carb','vegetarian','pescatarian','calorie smart','classic','guilt free','chicken','meat','gourmet','clear filter']

     useEffect(() => {
       if(!inRegister.includes('2')){
            navigate('/boxes')
          }
          
           setInRegister(['0','1','2','3'])
     },[])
     

        useEffect(() => {
      
      const data= localStorage.getItem('userdata')
    

    }, [formData])

  



  useEffect(()=> {


 var now = new Date();
var today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
var lastSunday = new Date(today.setDate(today.getDate()-today.getDay()));
 const offset = lastSunday.getTimezoneOffset()
lastSunday = new Date(lastSunday.getTime() - (offset*60*1000))
lastSunday = lastSunday.toISOString().split('T')[0]

    const fetchMeals = async(dateused) => {
    
    try{
       const response = await axios.get(`/api/auth/newMeals`, {
        params:{
          dateUsed: dateused
        }
      })
        .then(res=>{
          const mealsData = res.data.mergedResults
      
          const prefLength = preferences.length
          

              switch(prefLength){
                case 1:
                    mealsData.sort((a,b) => a.mealtype[0].includes(preferences[0])  ? -1 : 1)
                    break;
                case 2:
                    mealsData.sort((a,b) => a.mealtype[0].includes(preferences[0]) ||  a.mealtype[0].includes(preferences[1])  ? -1 : 1)
                     break;
                 case 3:
                    mealsData.sort((a,b) => a.mealtype[0].includes(preferences[0]) ||  a.mealtype[0].includes(preferences[1])||  a.mealtype[0].includes(preferences[2]) ? -1 : 1)
                    break;
                case 4:
                    mealsData.sort((a,b) => a.mealtype[0].includes(preferences[0]) ||  a.mealtype[0].includes(preferences[1])||  a.mealtype[0].includes(preferences[2]) ||  a.mealtype[0].includes(preferences[3]) ? -1 : 1)
                     break;
                case 5:
                    mealsData.sort((a,b) => a.mealtype[0].includes(preferences[0]) ||  a.mealtype[0].includes(preferences[1]) ||  a.mealtype[0].includes(preferences[2]) ||  a.mealtype[0].includes(preferences[3]) ||  a.mealtype[0].includes(preferences[4])  ? -1 : 1)
                    break;
                default:
                    break;

              }
               
                setFilteredMealsList(mealsData)
          setMealsList(mealsData)
        })
      }catch(error){
        console.log(error)
    }
  }
 
fetchMeals(lastSunday)
  
  },[])

  const handleFilter = (selectedCategory) => {
    
    if(selectedCategory === 'clear filter'){
     
      setSelectedFilters([]);
    }else{
if (selectedFilters.includes(selectedCategory)) {
      let filters = selectedFilters.filter((el) => el !== selectedCategory);
      setSelectedFilters(filters);

    } else {
      setSelectedFilters([...selectedFilters, selectedCategory]);
            
    }
    }
    
    }  

    useEffect(() => {
   filterItems();
   
}, [selectedFilters, filteredMealsList])
  
    const filterItems = () => {

    if (selectedFilters.length > 0) {
      
      let tempItems = selectedFilters.map((selectedCategory) => {
      
        let temp = filteredMealsList.filter((item) => item.mealtype.some(type => type.includes(selectedCategory)));

        return temp;
      });

     
      const finaltempItems = tempItems.flat().reduce((acc, current) => {
        const index = acc.findIndex((item) => item._id.includes(current._id));
      
        if (index === -1) {
          acc.push(current);
        }
        return acc;
      }, []);

      setMealsList(finaltempItems.flat());
     
    } else {
      setMealsList(filteredMealsList);
    }
  };

const handleGetRequest = async (lastSunday,index) => {


    setIndex(index)
    try {
      const response = await axios.get(`/api/auth/newmeals`, {
        params: {
          dateUsed: lastSunday
        }
      })
       .then(res=>{
          const mealsData = res.data.mergedResults
          
       
          const countMatches = mealsData.filter(meal => meals.includes(meal.matchingFieldFromLinks)).length
          
          setmealsCount(countMatches)
         
          
          setMealsList(mealsData)
          setFilteredMealsList(mealsData)
        })
      }catch(error){
        console.log(error)
    
    }
  };


    useEffect(() => {
   
    console.log(nmeals)
     const buttons = document.querySelectorAll('.addMeal');
     if(mealsCount>=nmeals){
      
      buttons.forEach((button) => {
      button.classList.add("disabled");
       
      });
    }else{
     
        buttons.forEach((button) => {
        button.classList.remove("disabled");
       
      });
    };
     setFormData((prevData) => ({
      ...prevData,
      nmeals: nmeals
    }))
    
    

    }, [mealsCount,nmeals,index,meals])

    const handleReload=()=>{
      if(mealsCount==nmeals){
      const buttons = document.querySelectorAll('.addMeal')
      buttons.forEach((button) => {
        button.classList.add("disabled");
      });
    }

    
  }
  const addMeals = () => {
    
    setNoMeals(prevState => prevState + 1)
    setNmeals(prevState => prevState + 1)
   
  }

  // const reduceMeals = () => {
  //   setNoMeals(prevState => prevState - 1)
  //   setNmeals(prevState => prevState - 1)
  // }

    const reload = handleReload();

    const handleNext = () => {
      
       if(mealsCount < 3){
           
            handleOpen()
       }else{
        localStorage.setItem('userdata', JSON.stringify(formData)); 
        navigate('/signup');
       }
      
    };

    const handleOpen = () => {
     
       setOpen(true);
    };
  
    const handleSelectionAdd = (event) => {
       
          if(mealsCount!=nmeals){
           
          const buttons = document.querySelectorAll('.addMeal');
            buttons.forEach((button) => {
            button.classList.remove("disabled")
            });
          const key = event.currentTarget.closest(".mealCard").id
          // console.log(key)
          setFormData((prevData) => ({
        ...prevData,
        mealSelections: [...prevData.mealSelections, key]
          }));

          

          setKeys((prevkeys) => [key,...prevkeys ])
         
          setmealsCount(prevState => mealsCount +1)
          let counter = event.currentTarget.closest(".mealCard").querySelector(".counter")
          let button = event.currentTarget.closest(".mealCard").querySelector("button")
          counter.innerText =  parseInt(counter.innerText) +1
          button.classList.remove('show')
          button.classList.add('hide') 

          event.currentTarget.closest(".mealCard").classList.add("addactive")
          let imgtoAdd = event.currentTarget.parentNode.parentNode.parentNode.querySelector(".content img").src
    
   setSmallimgs((prevData) => [...prevData, imgtoAdd])

   setFormData((prevData) => ({
        ...prevData,
        smImages: [...prevData.smImages, imgtoAdd],
          })); 
  
         
          const lilist = document.querySelector(".chosenmeals")
          const liItems = lilist.getElementsByTagName("li")
          for(let i =0 ; i < liItems.length ; i++){
            const content = liItems[i].innerHTML
            if(content === '' )
              {
                liItems[i].innerHTML = `<img src="${imgtoAdd}"/>`
               return
              }
          }
         
        }
  }
 useEffect(() => {
  
  setNewImgSrc(imgSrc)
  
 },[imgSrc])

const handleSelectionRemove = (event) => {

    const key = event.currentTarget.closest(".mealCard").id
   
    

    const index = keys.indexOf(key);
    if (index > -1) {
        keys.splice(index, 1);
        setKeys([...keys]);
        
    }        
   
      setFormData((prevData) => {
      let updatedMeals = Array.isArray(prevData.mealSelections) ? [...prevData.mealSelections] : [];
      if (index === null) {
        if (key.trim() !== '') {
          updatedMeals = [...updatedMeals, key];
        }
      } else {
        const mealIndex = updatedMeals.indexOf(key);
        if (mealIndex !== -1) {
          updatedMeals.splice(mealIndex, 1);
        }
      }
      return {
        ...prevData,
        mealSelections: updatedMeals
      };
    });
    if (index === null) key=('');
  
        
          setmealsCount(prevState => mealsCount - 1 );
          let counter = event.currentTarget.closest(".mealCard").querySelector(".counter")
          let button = event.currentTarget.closest(".mealCard").querySelector("button")
         
           
          counter.innerText =  parseInt(counter.innerText) -1
           
            if ( counter.innerText  === "0") {
              button.classList.remove('hide')
              button.classList.add('show')
              event.currentTarget.parentNode.parentNode.parentNode.classList.remove("addactive");
            }
          
          let imgtoRemove = event.currentTarget.parentNode.parentNode.parentNode.querySelector(".content img").src
            setSmallimgs((prevData) => {
              const indexToRemove = prevData.findIndex((image) => image === imgtoRemove);
              
               if (indexToRemove !== -1) {
           return [...prevData.slice(0, indexToRemove), ...prevData.slice(indexToRemove + 1)];
              }

              return prevData;
            }

            );
          setFormData((prevData) => ({
        ...prevData,
        smImages: prevData.smImages.filter((image) => image !== imgtoRemove),
          }));
          
          const lilist = document.querySelector(".chosenmeals")
          const liItems = lilist.getElementsByTagName("li")
          
          for(let i =0 ; i < liItems.length ; i++){
            
            if(liItems[i].innerHTML !== '' ){
            const content = liItems[i].querySelector("img").src
            
           
            if(content === imgtoRemove)
              {                
                liItems[i].innerHTML = ``
                return
              }

            }

            
          }
        }
       
    



const getMealCount = () => {
    return formData.mealSelections.reduce((acc, mealId) => {
      acc[mealId] = (acc[mealId] || 0) + 1;
      return acc;
    }, {});
  };

 const mealCounts = getMealCount();

  const emptySlots = nmeals - smallimgs.length;

    
    const containsGourmet = (mealtype) => {
  if (Array.isArray(mealtype) && mealtype.length > 0) {
    // Split the first element of the array by commas and trim whitespace
    const typesArray = mealtype[0].split(',').map(item => item.trim());
    // Check if 'gourmet' is in the resulting array
    return typesArray.includes('gourmet');
  }
  return false;
};
    
    
    

  return (
    <>
  
 <div className="container">
    
    <h1>Meals</h1>
           <h2>Select your meals</h2>

<div className="mealsList">
    <div className="mealImages">
     
<ul className="chosenmeals">
  
  {smallimgs.map((img, index) => (
  <li className="chosenmeal" key={index} data-index={index}><img src={img} alt="image" /></li>
))}
 {Array.from({ length: emptySlots }, (_, index) => (
          <li className="chosenmeal"  key={smallimgs.length + index}></li>
        ))}



</ul>
<div className='selectedTextDiv'>
<p className='selectedText'>You have selected {mealsCount} meals</p>

{noMeals < 5 ? <p className="addmeals" onClick={addMeals}>Add meal</p>: ''}
{/* {noMeals > 3 ? <p onClick={reduceMeals}>Remove meal</p>: ''} */}
{/* <p>{noMeals}</p> */}
</div>
<button className="nextButton" onClick={handleNext}>Next</button>
      </div>
      <div className="datesContainer">
     <WeeklyDateRanges  onRequest={handleGetRequest} /> 
</div>

 <div className="filterContainer">
    <ul className="filters" >
            {filters.map((category, idx) => (
              <li className={`filterbutton ${category.replace(/ /g,'')} ${selectedFilters?.includes(category) ? "active" : ""} `} key={`filters-${idx}`} onClick={() => handleFilter(category)}>{category}</li>
            ))}
              
            </ul>
     </div>
       <div className="mealList">
        {
    
            
            MealsList.map( meal => {
              return <div  key={meal._id} id={meal.matchingFieldFromLinks} className={`mealCard ${meals.includes(meal.matchingFieldFromLinks)  ? "addactive" : ""}
              ${containsGourmet(meal.mealtype) ? "gourmet" : ""}`}>
                        
                       
                        {/* <div className="mealContent"> */}
                          <div className='imgContainer'> 
                          <img src={`/images/meals/${meal.image}`} alt="image" />
                          </div>
                           <div className='contentContainer'>
                          <div className="mealDetails">
                            
                              <h4>{meal.title}</h4>
                              <p>{meal.subtitle}</p>
                          </div>
                              
                        {/* </div> */}

                          <div className="mealtypes">       
                              <ul>
                              {meal.mealtype.toString().split(',').map((type, index) => (
                                <li key={index} className={type.replace(/ /g,'')}>{type}</li>
                              ))
                              }

                              </ul>
                          </div> 
                           
              
                  <div className="addRemove">
                 
                    <button  onClick={handleSelectionAdd} className={`addMeal ${formData.mealSelections.includes(meal.matchingFieldFromLinks) ? "hide" : ""}`}>Add Recipe</button>
              <p  onClick={handleSelectionAdd}>+</p>
              <p className="counter">{mealCounts[meal.matchingFieldFromLinks]}</p>
                <p  onClick={handleSelectionRemove}>-</p>
                </div>
                
                              
              </div>  
            </div>
          })

        }


      </div>
        
        </div> 

    
</div>

  <Modal isOpen={open} onClose={() => setOpen(false)} >
      
    <div className="modalTop"><span>Please check your selections</span><button className="close">X</button></div>
       
        <div className="modalBody">
           <div className='modalWarning'><p>Please Select {nmeals} Recipes</p></div>
      
    </div>
    </Modal>
    </>
  )
}

export default Meals