import React, { useRef, useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import axios from 'axios';
import AdminPanel from './adminPanel';

const UsersList = () => {
  const [user, setUser] = useState({});
  const [users, setUsers] = useState([])
  const [message, setMessage] = useState('');
  useEffect(() => {
    document.title = "Users List"
    const fetchUsers  = async () => {
      
       const res =  axios.get(`/api/auth/allusers`)
        .then(res=>{
          setUsers(res.data)
          console.log(res.data)
        })        
    }
    
    fetchUsers()
  },[])


  const deleteUser = async (id) => {
    try {
      await axios.delete(`/api/auth/deleteuser/${id}`);
      setUsers((prevusers) => prevusers.filter(user => user._id !== id));
      setMessage('User deleted successfully');
    } catch (err) {
      console.error(err);
    }
  }
  const deleteref = useRef();
function toggleDialog() {
   
  if(!deleteref.current){
    return
  } 
  deleteref.current.hasAttribute("open") ? deleteref.current.classList.add("closing") : deleteref.current.classList.remove("closing");
  deleteref.current.hasAttribute("open") ?  document.body.classList.remove("hideOverflow") : document.body.classList.add("hideOverflow");
   deleteref.current.hasAttribute("open") ? deleteref.current.close()  : deleteref.current.showModal();
}

const openDeleteDialog = (id) => {
    setUser(id);
    toggleDialog();
  };


  return (
    <>
    <div className='adminDash'>
    <h1>Users List</h1>
    <AdminPanel/>
       <div className='adminMainContent userList'>
        <div className="userTable">
          <table>
            <thead>
              <tr>
                <th>User Name</th>
                <th>Email</th>
                <th></th>
                
              </tr>
            </thead>
            <tbody>
              {users.map(user => (
                <tr key={user._id}>
                  <td>{user.firstName} {user.lastName}</td>
                  <td>{user.email}</td>
                  <td>
                     <button onClick={() => openDeleteDialog(user._id)}>Delete User</button>
                   <Link to={`/edituser?id=${user._id}`}> <button>View User</button></Link>
                 </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
       </div>
       </div>
        <dialog className="filter" ref={deleteref} id="deleteref">
  <div className="modalTop">Delete Meal<button className="close" onClick={toggleDialog}>X</button></div>
        
       <div className="modalBody">    
       <div className='bfDelete'>
        <p>Are you sure you want to delete this user?</p>
        <button onClick={toggleDialog}>Cancel</button>
        <button onClick={deleteUser}>Delete</button>
       </div>
       <div className='afDelete'>
        {message}
        <button onClick={toggleDialog}>Close</button>
        </div> 
      </div>
           
    
    </dialog>
    </>
  )
}

export default UsersList
