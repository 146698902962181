import React, { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { Link } from 'react-router-dom';

const LogoutButton = () => {
    const { authState, logout } = useContext(AuthContext);

    const handleLogout = () => {
        logout();
    };
    

    return (
        <>
            {authState.isAuthenticated ? (
                <button onClick={handleLogout}>Logout</button>
            ) : (
                <Link to="/login"><button>Login</button></Link>
            )}
        </>
    );
};

export default LogoutButton;
