import { useLocation,useNavigate,Link } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import axios from "axios" 
import UserPanel from "./userPanel";
import { AuthContext } from '../context/AuthContext';

const Orderhistory = (props) => {
  const { authState } = useContext(AuthContext);


    // useEffect(() => {
    //     props.messageSet('orderhistory')
    // })


    const orderHistory = authState.user.orderhistory
    const orderLength = orderHistory.length

  return (
    <>
    <div className="dashboardContainer">
          
           <div className="userContainer">
                {/* <div className="userLinks">
                <UserPanel />
                </div>
        */}
       <div className="userData"> 
        <div className="container">
    <h2>Order History</h2>
   <div className="dashboardPanel">
    
    {
     (orderLength === 0) ?<div><p>You have no order history yet</p></div>
     :orderHistory.map((order) => <div>{order.title}</div>)
      } 
    
    </div>
    </div>
    </div>
    </div>
    </div> 
</>
    )

  }

export default Orderhistory