import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';



function Adminlogin() {
    const { adminLogin, authState } = useContext(AuthContext);
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    const { email, password } = formData;

    useEffect(() => {
        if (authState.isAuthenticated) {
            navigate('/admindash');
        }
    }, [authState.isAuthenticated, navigate]);

    const onChange= function(e){ 
         document.querySelector('.error').style.display = 'none';
        setErrorMessage(''); 
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    const onSubmit = async e => {
        
        e.preventDefault();
        if(email === '' || password === '') {
            setErrorMessage('Please fill in all fields');
            document.querySelector('.error').style.display = 'block';
            return;
        }
        try {
            
            const response = await adminLogin(email, password);
           // console.log(response)
            //      console.log(response.token.user)
           
            if(response.success === false){
                document.querySelector('.error').style.display = 'block';
                setErrorMessage("The email or password does not match our records. Please double-check and try again.");
            }else{
               
            //if(response.token.user.role === 'admin'){
                navigate('/adminDash');
            // }else
            //     navigate('/dashboard'); // Redirect to dashboard after successful login
            }
        
           
            
        } catch (error) {
            console.log('Login failed',error);
            document.querySelector('.error').style.display = 'block';
            setErrorMessage('Login failed');
            // Handle login error (e.g., display error message)
        }
    };

    return (
        <div className='wrapper login'>
   <div className="container">
      <div className="signup">
        <div className="formContainer">
  <h1>Admin Login</h1>
        <form onSubmit={onSubmit}>
            <input type="email" name="email" value={email} onChange={onChange} placeholder='Email'  />
            <input type="password" name="password" value={password} onChange={onChange} placeholder='Password'  />
            <p className='error'>{errorMessage}</p>
            <button type="submit">Login</button>
        </form>
        </div>
        </div>
        </div></div>
    );
}

export default Adminlogin;
