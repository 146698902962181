import { useLocation,useNavigate,Link } from "react-router-dom";
import { useEffect, useState, useContext, useRef} from "react";
import axios from "axios" 
import WeeklyDateRanges from "../components/weeklydaterange";
import { AuthContext } from '../context/AuthContext';
import '../styles/meals.css'
import UserPanel from "./userPanel";




const MyMeals = ()=>{
   
 const { authState } = useContext(AuthContext);
    // console.log(authState);
  const location  = useLocation();
const [loading, setLoading] = useState(false);
  const [MealsList, setMealsList] = useState([])
  const [keys, setKeys]= useState([])
  //mealscount is meals
  const [mealsCount, setmealsCount] = useState();
  const [filteredMealsList, setFilteredMealsList ] = useState([])
  const preferences =authState.user.mealPreferences;
  const meals = authState.user.noMeals
  const values = authState.user.mealSelections 
  const userId= authState.user._id

  const [errorMessage, setErrorMessage] = useState('')
  const [dateused, setDateused] = useState('')
  const [updateMessage, setUpdateMessage] = useState('')


  const counts = {};
values.forEach(function (x) { counts[x] = (counts[x] || 0) + 1; });

const navigate = useNavigate();

 useEffect(() => {
   document.title = 'My Meals'
     const buttons = document.querySelectorAll('.addMeal');
     if(mealsCount==meals){
     
      buttons.forEach((button) => {
      button.classList.add("disabled");
       
      });
    }else{
       buttons.forEach((button) => {
        button.classList.remove("disabled");
       
      });
    };
    
    

    }, [mealsCount,meals])

     const handleReload=()=>{
      if(mealsCount==meals){
      const buttons = document.querySelectorAll('.addMeal')
      buttons.forEach((button) => {
        button.classList.add("disabled");
      });
    }

    
  }

    const reload = handleReload();
const dialogRef = useRef();
const toggleDialog = async function(id) {

  if(!dialogRef.current){
    return
  } 
  dialogRef.current.hasAttribute("open") ? dialogRef.current.classList.add("closing") : dialogRef.current.classList.remove("closing");
  dialogRef.current.hasAttribute("open") ?  document.body.classList.remove("hideOverflow") : document.body.classList.add("hideOverflow");
   dialogRef.current.hasAttribute("open") ? dialogRef.current.close()  : dialogRef.current.showModal();

   if(dialogRef.current.hasAttribute("open")){
    

  }

}
const handleClick = async(e) => {
  toggleDialog();
  setLoading(true);
   e.preventDefault();
     try {                         
      const response = await axios.put(`/api/auth/updateUserMealSelections/${userId}`, {
        mealSelections:values
      })
       .then(response=>{ 
      setUpdateMessage(response.data.message)
      
     setLoading(false)


    
       
      })
      
       
         }catch(error){
        console.error(`Error updating meal selections:`, error);
    
    }
     
       }
      
    


  
  useEffect(()=> {




var now = new Date();
var today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
var lastSunday = new Date(today.setDate(today.getDate()-today.getDay()));
 const offset = lastSunday.getTimezoneOffset()
lastSunday = new Date(lastSunday.getTime() - (offset*60*1000))
lastSunday = lastSunday.toISOString().split('T')[0]

    const fetchMeals = async(dateused) => {
    
    try{
       const response = await axios.get(`/api/auth/newMeals`, {
        params: {
          dateUsed: dateused
        }
      })
        .then(res=>{
          const mealsData = res.data
          
          const prefLength = preferences.length
          const countMatches = mealsData.filter(meal => values.includes(meal._id)).length
          
          setmealsCount(countMatches)
          


              switch(prefLength){
                case 1:
                    mealsData.sort((a,b) => a.mealtype[0].includes(preferences[0])  ? -1 : 1)
                    break;
                case 2:
                    mealsData.sort((a,b) => a.mealtype[0].includes(preferences[0]) ||  a.mealtype[0].includes(preferences[1])  ? -1 : 1)
                     break;
                 case 3:
                    mealsData.sort((a,b) => a.mealtype[0].includes(preferences[0]) ||  a.mealtype[0].includes(preferences[1])||  a.mealtype[0].includes(preferences[2]) ? -1 : 1)
                    break;
                case 4:
                    mealsData.sort((a,b) => a.mealtype[0].includes(preferences[0]) ||  a.mealtype[0].includes(preferences[1])||  a.mealtype[0].includes(preferences[2]) ||  a.mealtype[0].includes(preferences[3]) ? -1 : 1)
                     break;
                case 5:
                    mealsData.sort((a,b) => a.mealtype[0].includes(preferences[0]) ||  a.mealtype[0].includes(preferences[1]) ||  a.mealtype[0].includes(preferences[2]) ||  a.mealtype[0].includes(preferences[3]) ||  a.mealtype[0].includes(preferences[4])  ? -1 : 1)
                    break;
                default:
                    break;

              }
               
              
          setMealsList(mealsData)
        })
      }catch(error){
        console.log(error)
    }
  }
 
fetchMeals(lastSunday)
  
    
  },[])

  const handleGetRequest = async (lastSunday) => {
    
    setmealsCount(0)


    try {
      const response = await axios.get(`/api/auth/newmeals`, {
        params: {
          dateUsed: lastSunday
        }
      })
       .then(res=>{
          const mealsData = res.data
          const countMatches = mealsData.filter(meal => values.includes(meal._id)).length
          
          setmealsCount(countMatches)
         
          
          setMealsList(mealsData)
          setFilteredMealsList(mealsData)
        })
      }catch(error){
        console.log(error)
    
    }
  };

const handleSelectionAdd = (event) => {


           if(mealsCount!=meals){
          const buttons = document.querySelectorAll('.addMeal');
          buttons.forEach((button) => {
        button.classList.remove("disabled")
          });
        }
          const key = event.currentTarget.closest(".mealCard").id
      
          if(mealsCount<meals){
          values.push(key)
          
          setKeys((prevkeys) => [key,...prevkeys ])
          
          setmealsCount(prevState => mealsCount +1)
          let counter = event.currentTarget.closest(".mealCard").querySelector(".counter")
          let button = event.currentTarget.closest(".mealCard").querySelector("button")
          counter.innerText =  parseInt(counter.innerText) +1
          button.classList.remove('show')
          button.classList.add('hide') 

          event.currentTarget.closest(".mealCard").classList.add("addactive")
          
  }
}



const handleSelectionRemove = (event) => {

    const key = event.currentTarget.closest(".mealCard").id
    
    const index = values.indexOf(key);
  
    if (index > -1) {
      
      values.splice(index,1)

        keys.splice(index, 1);
        setKeys([...keys]);
        
         if (mealsCount > 0) { 
             setmealsCount(prevState => mealsCount - 1 );
            let counter = event.currentTarget.closest(".mealCard").querySelector(".counter")
            let button = event.currentTarget.closest(".mealCard").querySelector("button")
         
      
         counter.innerText =  parseInt(counter.innerText -1) 
               
            if (  counter.innerText   === "0") {
              button.classList.remove('hide')
               button.classList.add('show')
               event.currentTarget.parentNode.parentNode.classList.remove("addactive");
            }
            
         }
       
    }

}



    return(
        <>
        <div className="dashboardContainer">
          
           <div className="userContainer">
                {/* <div className="userLinks">
                <UserPanel />
                </div>
        */}
       <div className="userData container ">        
 <div className="userDashboard">
            <h1>Select Meals</h1>
            <h2>Change or select your Meals</h2>
            

            <div className='dashboardContent'>

           <div className="leftPanel">
          
 <div className="datesContainer">
     <WeeklyDateRanges  onRequest={handleGetRequest} /> 
</div>
   
       <div className="mealList myMeals">
        {
    
            
            MealsList.map( meal => {
              return <div  key={meal._id} id={meal._id} className={`mealCard ${values.includes(meal._id) ? "addactive" : ""}`}>
                        
                          <div className="imgContainer">
                          <img src={`../images/meals/${meal.image}`} alt="image" />
                          </div>
                          <div className="contentContainer">
                          <div className="mealDetails">
                         
                              <h4>{meal.title}</h4>
                              <p>{meal.subtitle}</p>
                          </div>
                              
                        
                         
                          <div className="mealtypes">       
                              <ul>
                              {meal.mealtype.toString().split(',').map((type, index) => (
                                <li key={index} className={type.replace(/ /g,'')}>{type}</li>
                              ))
                              }

                              </ul>
                             
                          </div>    
               
                  <div className="addRemove">
                    <button className={`mealcardButton addMeal ${counts[meal._id]>0 ? "hide" : "show"} `}  onClick={handleSelectionAdd}> Add Recipe</button>
              <p style={{cursor: 'pointer'}} onClick={handleSelectionAdd}>+</p>
              <p className="counter">{counts[meal._id] || 0} </p>
                <p style={{cursor: 'pointer'}}  onClick={handleSelectionRemove}>-</p>
                </div>
                

           
            </div>
              
                                    </div>
            })

        }


      </div>
      <div className="saveButtons">
        <button className="nextButton" onClick={handleClick}>Save</button>
        
      </div>
        </div>    
        </div>
          </div>    
       </div>
       </div>
</div>
          <dialog className="filter"  ref={dialogRef} id="PaymentRef">
  <div className="modalTop"><span>Processing payment</span><button className="close" onClick={toggleDialog}>X</button></div>
<div className="modalBody"> 
           <p>Update Meals</p>
              {loading ? <img src="../images/icons/loading.gif" alt="loading" className="loading" /> : <p>{updateMessage}</p>}    
            </div>
          
            
            </dialog> 
      </>
 

 
    )
}

export default MyMeals