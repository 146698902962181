import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import axios from 'axios';
import AdminPanel from './adminPanel';

const AddUser = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    userType: 'admin',
  });

  const [errors, setErrors] = useState({
    name: '',
    email: '',
    password: '',
  });


const validateForm = () => {
  
    let formValid = true;
    const newErrors = {};

    if (!formData.name) {
      formValid = false;
      newErrors.name = 'Name is required';
      console.log("no name")
    }

    if (!formData.email) {
      formValid = false;
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      formValid = false;
      newErrors.email = 'Email is invalid';
    }

    if (!formData.password) {
      formValid = false;
      newErrors.password = 'Password is required';
    } else if (formData.password.length < 6) {
      formValid = false;
      newErrors.password = 'Password must be at least 6 characters';
    }

    setErrors(newErrors);
    return formValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    try {
      const response = await fetch('api/auth/createAdmin', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        alert('User added successfully');
      } else {
        alert('Error adding user');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };




  return (
   
     <>
    <div className='adminDash'>
    <h1>Add User</h1>
    <AdminPanel/>
       <div className='adminMainContent adduser'>
        <h3>Add A New Administrator Account</h3>
       
      <form onSubmit={handleSubmit}>
        <div>
        <label htmlFor="name">Name</label>
        <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} />
        {errors.name && <p className='errorMessage'>{errors.name}</p>}
        </div>
        <label htmlFor="email">Email</label>
        <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} />
        {errors.email && <p className='errorMessage'>{errors.email}</p>}
        <label htmlFor="password">Password</label>
        <input type="password" id="password" name="password"  value={formData.password} onChange={handleChange} />
      {errors.password && <p className='errorMessage'>{errors.password}</p>}
        
        <button type="submit">Add User</button>
      </form>
     </div>
     </div>
       </>
  )
}

export default AddUser