import { useLocation,useNavigate,Link } from "react-router-dom";
import React, { useEffect, useState,useRef, useContext } from 'react';
import axios from "axios"
import UserPanel from "./userPanel";

const Offers = (props) => {
    //  useEffect(() => {
    //     props.messageSet('offers')
    // })
const [offersmessage, setOffersMessage] = useState('');
  const handleGetRequest = async (newDate, selectedIndex) => {
    
  
    try {
      const response = await axios.get(`/api/auth/offers`)
       .then(res=>{ 
       
          console.log(res.data)
          //authState.user.mealSelections = res.data
           if(res.data.length === 0){
            setOffersMessage('There are currently no offers available. Please check back later.')
          //      setMealsButtonMessage('Select meals')
          //      setNoresultsMessage('You have not selected any meals for this week. Please click the select meals button to add some.')
          //  }else{
          // setMealsButtonMessage('Change meals')
          // setNoresultsMessage('')
          //  }
          //  setMymeals(mymealsData)
          //setFilteredMealsList(mealsData)
           }
        })
      }catch(error){
        console.log(error)
    
    }
    
  };


handleGetRequest();

  return (
    <>
    <div className="dashboardContainer">
          
           <div className="userContainer">
                {/* <div className="userLinks">
                <UserPanel />
                </div> */}
       
       <div className="userData">  
    <h1>Offers</h1>
    <div className="dashboardPanel">
    <p>{offersmessage}</p>
</div>
</div>
</div>
</div>
</>
  )
}

export default Offers