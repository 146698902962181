import React, { useState,useRef,useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from "axios" 
import NextDaysComponent  from './deliverydays';

const Summary = ({formData, inRegister, setInRegister} ) => {
const navigate = useNavigate();

 
        useEffect(() => {
      //  if(!inRegister.includes('5')){
      //       navigate('/boxes')
      //     }
          
           setInRegister(['0','1','2','3','4','5','6'])
    },[])
const userData = formData
// console.log(userData)

const preferences = userData.mealPreferences;
const values = userData.mealSelections;
const delslot = userData.deliverTimeSlot
const deliveryDay = userData.deliveryDay
const [mymeals, setMymeals] = useState([]);
const getpeople = userData.people;
    const getmeals = userData.nmeals;
 
const [pricepermeal, setPricepermeal] = useState(50)
const [gourmetPrice, setGourmetPrice] = useState(10)
const [people, setPeople] = useState(getpeople)
const [meals, setMeals] = useState(getmeals)
const [timeSlot, setTimeSlot] = useState('')

const counts = {};

values.forEach(function (x) { counts[x] = (counts[x] || 0) + 1; });



    

const handleGetRequest = async () => {
   
  
    try {
      const response = await axios.get(`/api/auth/summarymeals`, {
        params: {
           id:values,
          
        }
      })
       .then(res=>{ 
       
          const mymealsData =  res.data
      
           setMymeals(mymealsData)
        
        })
      }catch(error){
        console.log(error)
    
    }
    
  };


  useEffect (() => {
   
  handleGetRequest()
  const formatTimeSlot = () => {
    const timeSlotMap = {
      1: "10:00 - 12:00",
      2: "12:00 - 14:00",
      3: "14:00 - 16:00",
      4: "16:00 - 18:00",
    };

    setTimeSlot(timeSlotMap[userData.deliverTimeSlot]);
  };

  formatTimeSlot();
  },[])

  function formatDateString(dateString) {
  // Step 1: Create a Date object from the string
  const date = new Date(dateString);
  
  // Step 2: Extract the day, month, and year
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  // Step 3: Create an array to map month index to month name
  const monthNames = [
    "January", "February", "March", "April", "May", "June", 
    "July", "August", "September", "October", "November", "December"
  ];

  // Step 4: Add appropriate suffix to the day
  const dayWithSuffix = day + getDaySuffix(day);

  // Step 5: Format the final string
  return `${dayWithSuffix} ${monthNames[monthIndex]}`;
}

// Helper function to get the correct suffix for the day
function getDaySuffix(day) {
  if (day >= 11 && day <= 13) {
    return "th"; // Handle 11th, 12th, 13th as special cases
  }
  switch (day % 10) {
    case 1: return "st";
    case 2: return "nd";
    case 3: return "rd";
    default: return "th";
  }
}

const countGourmet = (meals) => {
  let totalCount = 0;
  // if (Array.isArray(mealtype) && mealtype.length > 0) {
  //   // Split the first element of the array by commas and trim whitespace
  //   const typesArray = mealtype[0].split(',').map(item => item.trim());
  //   // Count the occurrences of 'gourmet'
   
  //   return typesArray.filter(type => type === 'gourmet').length;
  // }
  // return 0;
 
     Object.entries(mymeals).map(([dateUsed,meals]) => {
       {meals.map((meal) => {
              
      // Check if "gourmet" is in the mealtype array (case-insensitive)
          const typesArray = meal.mealtype[0].split(',').map(item => item.trim());
          totalCount += typesArray.filter(type => type === 'gourmet').length;
       
        }
       )}
     });
   return totalCount;
};

 const totalGourmetCount = countGourmet(mymeals);

 const totalGourmetCost = totalGourmetCount * gourmetPrice;

const totalPrice = (people*getmeals)*pricepermeal+totalGourmetCost
//const totalPrice = (people*getmeals)*pricepermeal

const addHours = (date, hours) => {
  const newDate = new Date(date);
  newDate.setHours(newDate.getHours() + hours);
  return newDate;
};

// Function to get the next date for a given day that is at least 48 hours from today
const getNextDay = (day) => {
  const dayOfWeekMapping = {
    sunday: 0,
    wednesday: 3,
    friday: 5,
  };

  const today = new Date();
  const minDate = addHours(today, 48); // Date 48 hours from now
  const currentDay = minDate.getDay(); // Current day of the week (0-6)

  // Map the day string to the corresponding day-of-week index
  const targetDayOfWeek = dayOfWeekMapping[day.toLowerCase()];

  // Calculate the number of days to add to get to the next target day
  let daysUntilNextDay = (targetDayOfWeek + 7 - currentDay) % 7;

  // If the day is today but less than 48 hours, move to the next occurrence
  if (daysUntilNextDay === 0) {
    daysUntilNextDay = 7;
  }

  const nextDay = new Date(minDate);
  nextDay.setDate(minDate.getDate() + daysUntilNextDay);

  return nextDay;
};

 const nextDeliveryDay = getNextDay(userData.deliveryDay);
  const nextWednesday = getNextDay('wednesday');
  const nextFriday = getNextDay('friday');
  console.log(nextDeliveryDay);

const handleSubmit=(e) => {
  e.preventDefault();
  navigate('/checkout');
}

  return (
    <div className="container ">
    <h1>Order Summary</h1>
    
    <div className="summary">
       
        <div className='summaryDiv flex'>
             <h2>Meal Details</h2>
        
          {Object.entries(mymeals).map(([dateUsed, meals]) => (
        <div className='summaryMeals'  key={dateUsed}>
          <h2>Week starting {formatDateString(dateUsed)}</h2>
          
            {meals.map((meal) => (
              <>
               <div  key={meal._id} id={meal._id} className="mealCard">
                         <div className="content"> 
                          <div className="mealimgContainer">
                                                 <img src={`../images/meals/${meal.image}`} alt={meal.title} />
                                                 </div>
                           <div className="mealDetails">
                               <h4>{meal.title}</h4>
                               <p>{meal.subtitle}</p>
                            
                           </div>
                              
                         </div>
                        
                         <div className="mealtypes">       
                               <ul> 
                             {meal.mealtype.toString().split(',').map((type, index) => ( 
                                <li key={index} className={type.replace(/ /g,'')}>{type}</li> 
                              ))
                               } 

                               </ul>
                        </div>     
                
                <div className="mealsBottom"> 
                 {counts[meal._id] ? <p className="counterism">{counts[meal._id]} <span>serving(s)</span> </p> : null} 
               {/* <button onClick={() => toggleDialog(meal._id)}>View Recipe</button> */}
                </div>
                 </div>
           </>
            ))}
          
        </div>
      ))}
       
    </div>
 
       
        <div className='summaryDiv flex'>
       <div className='detailsdiv'>
         <h2>Your details</h2>
      <p>Name: {userData.fname} {userData.lname}</p>
      <p>Email: {userData.email}</p>
      <p>Phone: {userData.contact}</p>
      </div>
      <div className='detailsdiv'>
      <h2>Delivery Details</h2>
      <p>Address: {userData.addressLine1}<br/>{userData.addressLine2 ? `userData.addressLine2 <br/>`: ''}{userData.street}</p>
      <p>Area: {userData.area}</p>  
      <p>Town: {userData.town}</p>
       {userData.specialInstructions.length > 0 && (
      <p>Special Instructions: {userData.specialInstructions}</p>
      )}
      </div>
      <div className='detailsdiv'>
      <h2>Delivery Time</h2>
      <p> {userData.deliveryDay}'s between {timeSlot}</p>
      <p>Your first delivery will be on {nextDeliveryDay.toLocaleDateString()}</p>
      
      
     
      </div>  
      </div>
       <div className='summaryDiv '>
      <h2>Price Details</h2>
           <div className="boxesData">
        
      <div className="boxesPrice">
        <p>Price per serving: {pricepermeal}AED</p>
        <hr></hr>
        
        <p>{people*meals} Servings <span>{people*meals*pricepermeal}AED</span></p>
         {totalGourmetCount > 0 && (
            <p>Extra cost of {totalGourmetCount} gourmet meals: <span>{totalGourmetCost}AED</span></p>
            )} 
        <p>Delivery <span>Free</span></p>
         <hr></hr>
        <p className="totalPrice">Total Price <span>{totalPrice}AED</span></p>
       
      </div>
      
        </div>
      </div>
      <button type="submit" onClick={handleSubmit}>Next</button>
</div>

   </div>
  )
}

export default Summary