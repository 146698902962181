import React, { useEffect, useState,useRef, useContext } from 'react';
import { useLocation,useNavigate,Lin } from "react-router-dom";
import axios from "axios" 
import { AuthContext } from '../context/AuthContext';
import UserPanel from "./userPanel";

const Cancelplan = (props) => {
  
    const { authState, logout } = useContext(AuthContext);
    const [reason, setReason] = useState('');

  // useEffect(() => {
  //    props.messageSet('plansettings')
  // },[])
    
    const cancel = async(e) => {
      console.log(reason)
      try {                         
     const response = await axios.put(`/api/auth/cancelplan/${authState.user._id}`, {
       status:"inactive",
       reason: reason
     })
      .then(response=>{ 
       logout();
      })
      
      
        }catch(error){
       console.error(`Error cancelling plan:`, error);
   
   }
   
      }
  return (
    <>
    <div className="dashboardContainer">
          
           <div className="userContainer">
                {/* <div className="userLinks">
                <UserPanel />
                </div> */}
       
       <div className="userData">  
     <div className="container cancel">
    <h1>Cancel Plan</h1>
    <p>We are very sorry to see you go.</p>
    <p>Please Let us know why you are cancelling your plan</p>
    <select onChange={(e) => setReason(e.target.value)}>
      <option value="">Select Reason</option>
      <option value="Too expensive">Too expensive</option>
      <option value="Don't like the options">Don't like the options</option>
      <option value="Other">Other</option>      
    </select>

    <button popovertarget="confirmCancel">Cancel</button>
  
    </div>

    <div popover="auto" id="confirmCancel"  >
        <div className='undelay'></div>
        <div className='cancelPanel'>
        <h3>To confirm your cancellation please enter your password</h3> 
        <input type="password" />
        <button onClick={cancel}>Cancel Plan</button>

        <p>*You can reactivate your plan anytime just by logging in</p>
        </div>

    </div>

    </div>
    </div>
    </div>
    </>
  )
}

export default Cancelplan