import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

const ProtectedRoute = ({ children }) => {
    const { authState } = useContext(AuthContext);

    if (authState.loading) {
        return <div>Loading...</div>; // Or some loading spinner
    }
    // console.log(authState)

    return authState.isAuthenticated ? children : <Navigate to="/login" />;
};

export default ProtectedRoute;
