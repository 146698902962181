// dateUtils.js
function formatDateRange(range) {
  const { start, end } = range;
  const startMonth = start.toLocaleDateString('en-US', { month: 'long' });
  const endMonth = end.toLocaleDateString('en-US', { month: 'long' });

  return `${start.getDate()}-${end.getDate()}\n${startMonth}${startMonth !== endMonth ? ` - ${endMonth}` : ''}`;
}

export const getNextSunday = (startDate) => {
  const date = new Date(startDate);
  date.setDate(date.getDate() + (7 - date.getDay()) % 7);
  date.setUTCHours(0, 0, 0, 0);
  return date;
};

export const getWeeklyRangesUntil = (startDate, endDate) => {
  let currentDate = getNextSunday(startDate);
  const weeklyRanges = [];

  while (currentDate <= endDate) {
    const weekStart = new Date(currentDate);
    const weekEnd = new Date(currentDate);
    weekEnd.setDate(weekEnd.getDate() + 6);

    weeklyRanges.push({
      start: weekStart,
      end: weekEnd,
      formattedRange: formatDateRange({ start: weekStart, end: weekEnd })
    });

    currentDate.setDate(currentDate.getDate() + 7);
  }

  return weeklyRanges;
};

// Calculate the start date as the beginning of the current week (Sunday)
export const getCurrentWeekStartEnd = () => {
  const currentDate = new Date();
  const startdate = new Date(currentDate);
  startdate.setDate(startdate.getDate() - currentDate.getDay());
  startdate.setUTCHours(0, 0, 0, 0);

  // Calculate the end date as the end of the fourth week from the current week
  const endate = new Date(startdate);
  endate.setDate(endate.getDate() + 27);  // 4 weeks - 1 day (since startdate is Sunday)

  return { startdate, endate };
};
