import React, {  useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import axios from 'axios';
import AdminPanel from './adminPanel';


const EditUser = () => {
const[ user , setUser] = useState({})
const location = useLocation();
const queryParams = new URLSearchParams(location.search);
const userid = queryParams.get('id');
    const timeSlots = ['6-9am', '9-12am', '12-3pm', '3-6pm']
    const [delslot, setDelSlot] = useState('')  
  
  useEffect(() => {
    document.title = "EditUser"

    const getUser = async () => {
         try {
      const res = await axios.get(`/api/auth/getuser/${userid}`)
         .then((response) => {
          setUser(response.data);
          setDelSlot(response.data.deliverTimeSlot)
         }) 
        } catch (error) {
           console.error(error);
         }
    };
    getUser();
  }, [])

  const updateUser = async () => {
    try {
      await axios.put(`/api/auth/updateuser/${userid}`, user);
    } catch (err) {
      console.error(err);
    }
  };


    useEffect(() => {
    const delset =  () => {
     switch(delslot) {
  case "1":
    setDelSlot(timeSlots[0]);
    
    break;
  case "2":
    setDelSlot(timeSlots[1]);
    break;
  case "3":
    setDelSlot(timeSlots[2]);
    break;
  case "4":
    setDelSlot(timeSlots[3]);
    break;
  
}
    }
    delset()
  console.log(delslot)     
    },)
   



 



  return (
    <div className='adminDash'>
    <h1>EditUser</h1>
     <AdminPanel/>
       <div className='adminMainContent'>
        <h2>Plan for {user.firstName} {user.lastName}</h2>
        <div className='panelContainer'>
      <div className='panelContent'>
        <h3>User details</h3>
        <p>User Name: <span>{user.firstName} {user.lastName}</span><span></span></p>
        <p>User email:<span>user.email</span></p>
        <p>User Address:<span>{user.addressLine1}<br/>{user.addressLine2}<br/>{user.addressLine3}<br/>{user.street}<br/>{user.area}<br/>{user.town}</span></p>        
        <p>User Status:<span>{user.status}</span></p>
        <p>Date joined:<span>{user.dateJoined}</span></p>
      </div>  

      <div className='panelContent'>
        <h3>User plan details</h3>
        <p>Number of people:<span>{user.noPeople}</span></p>
        <p>Number of meals:<span>{user.noMeals}</span></p>
        <p>Plan cost<span>{user.total}AED </span></p><br/>
        <p>User Delivery details:<span>{user.deliveryDay} {delslot}</span></p> 
        <p>User Special Instructiions:<span>{user.specialInstructions ? user.specialInstructions : "None"}</span></p>  
        </div>     
      </div>
    </div>
    </div>
  )
}

export default EditUser