import React from "react";
 
const PageNotFound = () => {
    return (
        <div className="pagenotfound">
            <h1>404 Error</h1>
            <h2>Page Not Found</h2>
        </div>
    );
};
 
export default PageNotFound