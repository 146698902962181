import React from 'react'
import '../styles/howitworks.css'

function Howitworks() {
  return (
    <div className="mainContainer howitworks">
      <div className="screen0">
    <h1>How it works</h1>
    {/* <img src="./assets/images/selectmeal.png" alt="" /> */}

            <div>
                
                <p>Delicious home cooked food in just 4 easy steps.</p>

            </div>

</div>
        
        

            <div className="screen1 ">
<div className="svgSectionH">
                <object className="newSVG" type="image/svg+xml" data="./assets/images/add.svg"></object>
           </div>
                <div className="instro">
                    <div><span>1</span></div>
                    <p> Select how many people you are cooking for and how many times a week you will be cooking. Select
                        the date and time that you prefer to recieve your package every week. Lorem ipsum dolor sit amet.
                       Aute ea ad anim amet cillum aute. Ipsum dolor Lorem laborum adipisicing enim ullamco dolore elit quis consectetur incididunt eiusmod. Est ex sit esse sit magna duis ad commodo cupidatat et pariatur commodo. Sint labore cillum est Lorem. Aliquip est labore esse elit. Consequat ullamco et non est incididunt non.

Enim anim elit do et ipsum in nostrud commodo dolore dolor minim. Lorem in non non nostrud nulla et minim. Adipisicing minim nostrud ut esse ullamco cupidatat ut ea consectetur non ex culpa. Fugiat aliqua cillum ad velit. Qui consectetur laboris et aliquip ex labore eiusmod.

Veniam est labore excepteur pariatur quis. Ipsum ex labore proident ut in laboris irure dolore. Sit dolor cillum dolor id ullamco ut nostrud anim. Enim commodo do in cupidatat esse amet deserunt minim dolore commodo ipsum velit. Non sunt tempor esse pariatur veniam. Et officia amet consectetur ullamco incididunt aute duis. Ipsum tempor consequat id fugiat.

Dolor consectetur aliqua commodo magna. Enim non ipsum aliquip in et adipisicing ea labore magna enim. Velit ad pariatur cupidatat nisi. Tempor reprehenderit officia fugiat mollit laborum nostrud Lorem deserunt proident. Excepteur proident adipisicing culpa reprehenderit commodo commodo do reprehenderit nisi esse reprehenderit occaecat sint. Ipsum consequat nisi occaecat cupidatat aute amet ullamco duis cupidatat id. Mollit mollit incididunt sit incididunt qui mollit tempor id do est.  </p>
                </div>
            </div>

            <div className="screen2">
<div className=" svgSectionH">
                <object className="newSVG" type="image/svg+xml" data="./assets/images/meals.svg"></object>
            </div>
            <div className="instro">
                <span>2</span>
                <p>Select your meals from 20 recipes</p>
            </div>
            </div>


            <div className="screen3">

                <div className="svgSectionH">
                <object className="newSVG" type="image/svg+xml" data="./assets/images/deliver.svg"></object>
            </div>
            <div className="instro">
                <span>3</span>
                <p>Receive your order when you want it</p>
            </div>
            </div>

            <div className="screen4">

            <div className="svgSectionH">
              <object className="newSVG" type="image/svg+xml" data="./assets/images/cook.svg"></object>
            </div>
            <div className="instro">
                <span>4</span>
                <p>Cook It!</p>
            </div>

          </div>
          </div>
  )
}

export default Howitworks