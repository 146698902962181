import React, { useEffect, useState,useRef, useContext } from 'react';
import { useLocation,useNavigate,Link } from "react-router-dom";
import axios from "axios" 
import { AuthContext } from '../context/AuthContext';
import UserPanel from "./userPanel";

import WeeklyDateRanges from '../components/weeklydaterange';

const Plansettings = (props,onRequest) => {
  const { authState, refreshData } = useContext(AuthContext);
const [mymeals, setMymeals] = useState([]);
const [newmeals, setNewmeals] = useState([]);
const [noresultsMessage, setNoresultsMessage] = useState('');
const [mealsButtonMessage, setMealsButtonMessage] = useState('Change meals');
const [mealsCount, setmealsCount] = useState(0);
const [keys, setKeys]= useState([])
const [selectedIndex, setSelectedIndex] = useState('0');
const [delSlotMessage, setDelSlotMessage] = useState('');
const [RecipeData, setRecipeData ] = useState([]);

useEffect(() => {
  document.title = 'Dashboard'

})
  
  const messageControl = props.changeMessage

 
 
const preferences = authState.user.mealPreferences;
const values = authState.user.mealSelections;
const delslot = authState.user.deliverTimeSlot

const counts = {};

values.forEach(function (x) { counts[x] = (counts[x] || 0) + 1; });
const meals = authState.user.noMeals



 const ref = useRef()

var now = new Date();
var today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
var lastSunday = new Date(today.setDate(today.getDate()-today.getDay()));
const offset = lastSunday.getTimezoneOffset()
lastSunday = new Date(lastSunday.getTime() - (offset*60*1000))
lastSunday = lastSunday.toISOString().split('T')[0]
const loadSunday = lastSunday;


 useEffect(() => {
    handleGetRequest(lastSunday,selectedIndex)
    
    
 },[])
   

    useEffect(() => {
    // Refresh data on component mount
    refreshData();


   
  }, []);



useEffect(() => {
   const reactivate = async () => {
    const userId = authState.user._id

      try {
        const res = await axios.put(`/api/auth/reactivate/${userId}`);
        
      } catch (err) {
        console.error(err);
      } 
    }
  if(authState.user.status === 'inactive'){
    console.log('reac')
   reactivate()
  }

   switch(delslot){
                case "1":
                    setDelSlotMessage('6-9am')
                    break;  
                case "2":
                    setDelSlotMessage('9-12pm')
                    break;
                case "3":
                    setDelSlotMessage('12-3pm')
                    break;
                case "4":
                    setDelSlotMessage('3-6pm')
                    break;
                case "5": 
                    setDelSlotMessage('6-10pm')  
                    break;
                default:
                    setDelSlotMessage('6-9am')
                    break;
            }
    
},[delslot])


 
const dialogRef = useRef();
const toggleDialog = async function(id) {
  setRecipeData([])
  if(!dialogRef.current){
    return
  } 
  dialogRef.current.hasAttribute("open") ? dialogRef.current.classList.add("closing") : dialogRef.current.classList.remove("closing");
  dialogRef.current.hasAttribute("open") ?  document.body.classList.remove("hideOverflow") : document.body.classList.add("hideOverflow");
   dialogRef.current.hasAttribute("open") ? dialogRef.current.close()  : dialogRef.current.showModal();

   if(dialogRef.current.hasAttribute("open")){
 try {
      const response = await axios.get(`/api/auth/getSingleMeal/${id}`)
       .then(res=>{ 
       
          const singleMealData =  res.data
                 
         setRecipeData(singleMealData)
         
         
         
        })
      }catch(error){
        console.log(error)
    
    }
 
  }
}

const handleGetRequest = async (newDate, selectedIndex) => {
    setSelectedIndex(prevState => selectedIndex)
  
    try {
      const response = await axios.get(`/api/auth/mymeals`, {
        params: {
           id:values,
          dateUsed: newDate
        }
      })
       .then(res=>{ 
       
          const mymealsData =  res.data
          //authState.user.mealSelections = res.data
          if(mymealsData.length === 0){
               setMealsButtonMessage('Select meals')
               setNoresultsMessage('You have not selected any meals for this week. Please click the select meals button to add some.')
           }else{
          setMealsButtonMessage('Change meals')
          setNoresultsMessage('')
           }
           setMymeals(mymealsData)
          //setFilteredMealsList(mealsData)
        })
      }catch(error){
        console.log(error)
    
    }
    
  };
  useEffect(() => {
    
dialogRef.current.addEventListener("click", e => {
  const dialogDimensions = dialogRef.current.getBoundingClientRect()
  if (
    e.clientX < dialogDimensions.left ||
    e.clientX > dialogDimensions.right ||
    e.clientY < dialogDimensions.top ||
    e.clientY > dialogDimensions.bottom
  ) {
    dialogRef.current.close()
  }
})
  })
  
  

 

    return (
        <>
         <div className="dashboardContainer">
          
           <div className="userContainer">
                {/* <div className="userLinks">
                <UserPanel />
                </div> */}
       
       <div className="userData">  
        <div className="userDashboard">
          <h1>Hello {authState.user.firstName}</h1>
            <h2>Welcome to your dashboard</h2>
            {/* <div className='userPanel'> 
           
           
            </div> */}
            <div className='dashboardContent'>
            <div className="leftPanel">
            <div className='dashboardPanel'>
            <h3>Meal Settings</h3>

            <h4>Current meals</h4>
            <div className="datesContainer">
             
                <WeeklyDateRanges   onRequest={handleGetRequest} />
              
            </div>
           <div className={  noresultsMessage  ? '' : 'mealList'}>
             {noresultsMessage ? <p>{noresultsMessage}</p> : null} 
        {
    
            
            mymeals.map( meal => {
              return <div  key={meal._id} id={meal._id} className="mealCard">
                        <div className="content"> 
                         <div className="mealimgContainer">
                                                <img src={`../images/meals/${meal.image}`} alt={meal.title} />
                                                </div>
                          <div className="mealDetails">
                              <h4>{meal.title}</h4>
                              <p>{meal.subtitle}</p>
                             
                          </div>
                              
                        </div>

                          <div className="mealtypes">       
                              <ul>
                              {meal.mealtype.toString().split(',').map((type, index) => (
                                <li key={index} className={type.replace(/ /g,'')}>{type}</li>
                              ))
                              }

                              </ul>
                          </div>    
                
             <div className="mealsBottom">
                {counts[meal._id] ? <p className="counterism">{counts[meal._id]} <span>serving(s)</span> </p> : null}
                <button onClick={() => toggleDialog(meal._id)}>View Recipe</button>

                </div>
            
              
                                    </div>
            })

        }
        </div>
       <Link to="/mymeals"><button>{mealsButtonMessage}</button></Link> 
        </div>
        </div>
        <div className='rightPanel'>
        <div className='dashboardPanel'>
           
            <h3>Preferences</h3>
            
            <ul className='dashboardPref'>
            {
                (preferences.length === 0) ? <li>You currently have no prefererences set</li>: preferences.map((value) =>
                <li className={`preferenceBox ${value.replace(/ /g,'')}`} key={value}> {value}</li>)
                

            }
            </ul>
       
             <Link to="/mypreferences"><button >Change Preferences</button></Link> 
        </div>
        

        <div className='dashboardPanel'>
            <h3>Plan Setup</h3>
            <ul className='planSetup'>
            <li className='peeps'>Number of people: {authState.user.noPeople}</li>
            <li className='guiltf'>Number of meals: {authState.user.noMeals}</li>
            </ul>
           <Link to="/myplan"><button >Change Setup</button></Link> 
        </div>

        <div className='dashboardPanel delivery'>
            <h3>Delivery</h3>
            <div><span>Delivery Window:</span> {authState.user.deliveryDay} {delSlotMessage}</div>
            <Link to="/mydelivery"><button >Change Delivery Window</button></Link> 

            
        </div>

        <div className='dashboardPanel'>
            <h3>Payment</h3>
            <div className='ccsection'><ul><li><img src="../images/icons/creditcards.png" alt="visa" />Card ending {authState.user.lfn}  </li></ul></div>
            <Link to="/paymentmethods"><button >Edit</button></Link> 

            
        </div>

        <div className='dashboardPanel'>
            <h3>Status</h3>
            <div> Your status is currently: {authState.user.status}</div>
            <div> You can skip a week in the <Link to="/mydeliveries">deliveries section</Link> </div>
          <Link to="/cancelplan"> <button >Cancel plan</button></Link>
            
        </div>
            </div>
            </div>
        </div>

         <dialog className="recipe mealsChange" ref={dialogRef} id="filterref">
  <div className="modalTop"><button className="close" onClick={toggleDialog}>X</button></div>
<div className="modalBody"> 

  {
    <>
    <div className='topSection'>
    <h1>{RecipeData.title}</h1>
    <h4>{RecipeData.subtitle}</h4>
    <p>{RecipeData.desc}</p>
    </div>
    <img src={RecipeData.image ? `/images/meals/${RecipeData.image}`: ''} alt="image" />
    <div className='recipeContent'>
    <p className='before'> Before you start
Please wash your hands and rinse all fresh fruits and vegetables prior to cooking. </p>
<div className='instructions'>
<h4>Instructions</h4>
<div className='insTitle'>{RecipeData.ins1title}</div>
<p>{RecipeData.ins1}</p>
<div className='insTitle'>{RecipeData.ins2title}</div>
<p>{RecipeData.ins2}</p>
<div className='insTitle'>{RecipeData.ins3title}</div>
<p>{RecipeData.ins3}</p> 
<div className='insTitle'>{RecipeData.ins4title}</div>
<p>{RecipeData.ins4}</p>
<div className='insTitle'>{RecipeData.ins5title}</div>
<p>{RecipeData.ins5}</p>
<div className='insTitle'>{RecipeData.ins6title}</div>
<p>{RecipeData.ins6}</p>
</div>
<div className='ingredients'>
<h4>Ingredients</h4>
 {RecipeData.ingredient1 && RecipeData.ingredient1 ?<p>{RecipeData.ingredient1 && RecipeData.ingredient1}</p>:''}
 {RecipeData.ingredient2 && RecipeData.ingredient2 ?<p>{RecipeData.ingredient2 && RecipeData.ingredient2}</p>:''}
 {RecipeData.ingredient3 && RecipeData.ingredient3 ?<p>{RecipeData.ingredient3 && RecipeData.ingredient3}</p>:''}
 {RecipeData.ingredient4 && RecipeData.ingredient4 ?<p>{RecipeData.ingredient4 && RecipeData.ingredient4}</p>:''}
 {RecipeData.ingredient5 && RecipeData.ingredient5 ?<p>{RecipeData.ingredient5 && RecipeData.ingredient5}</p>:''}
 {RecipeData.ingredient6 && RecipeData.ingredient6 ?<p>{RecipeData.ingredient6 && RecipeData.ingredient6}</p>:''}
 {RecipeData.ingredient7 && RecipeData.ingredient7 ?<p>{RecipeData.ingredient7 && RecipeData.ingredient7}</p>:''}
 {RecipeData.ingredient8 && RecipeData.ingredient8 ?<p>{RecipeData.ingredient8 && RecipeData.ingredient8}</p>:''}
 {RecipeData.ingredient9 && RecipeData.ingredient9 ?<p>{RecipeData.ingredient9 && RecipeData.ingredient9}</p>:''}
 {RecipeData.ingredient10 && RecipeData.ingredient10 ?<p>{RecipeData.ingredient10 && RecipeData.ingredient10}</p>:''}
</div>
<div className='allergens'>
  <h4>Allergens</h4>
  <p>{RecipeData.allergens}</p>
  <p className='warning'>Due to production methods, we cannot guarantee our products are completely free from any allergen such as Peanuts, Tree Nuts, Sesame Seeds, Milk, Egg, Fish, Crustaceans, Molluscs, Soya, Wheat, Gluten, Lupin, Mustard, Sulphur dioxide and Celery.</p>
</div>
<div className='nutrition'>
    <h4>Nutrition</h4>
  <p>Energy (kJ):<span>{RecipeData.energykj}</span></p>
  <p>Energy (kcal):<span>{RecipeData.energykcal}</span></p>
  <p>Protein:<span>{RecipeData.protein}</span></p>
  <p>Fat:<span>{RecipeData.fat}</span></p>
  <p>Of which saturated:<span>{RecipeData.saturated}</span></p>
  <p>Carbohydrates:<span>{RecipeData.carbs}</span></p>
  <p>Of which sugars: <span>{RecipeData.ofwhichsugars}</span></p>
  <p>Salt: <span>{RecipeData.salt}</span></p>
</div>
</div>

  </>
  }


</div>
  </dialog>
  </div>
  </div>
  </div>
   
        </>
    );
};

export default Plansettings;