import React, { useEffect, useState,useRef, useContext } from 'react';
import { useLocation,useNavigate,Lin } from "react-router-dom";
import axios from "axios" 
import { AuthContext } from '../context/AuthContext';
import UserPanel from "./userPanel";

const Paymentmethods = (props) => {
    // useEffect(() => {
    //     props.messageSet('paymentmethods')
    // })
    
   const { authState } = useContext(AuthContext);
  return (
    <div className="dashboardContainer">
          
           <div className="userContainer">
                {/* <div className="userLinks">
                <UserPanel />
                </div> */}
       
       <div className="userData">  
    <div>paymentmethods</div>
    </div>
    </div>
    </div>
  )
}

export default Paymentmethods