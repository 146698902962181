import React, { useState ,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


export const Preferences = ({formData, setFormData, inRegister, setInRegister} ) => {
  const [preference, setpreference] = useState('');
    const [pref, setpref] = useState([]);
    const navigate = useNavigate();
   
    useEffect(() => {
       if(!inRegister.includes('1')){
            navigate('/boxes')
          }
          
           setInRegister(['0','1','2'])
      },[])
     
    
   
     const handleChange = (e) => {
if(e.target.value === 'none'){
  
   setFormData((prevData) => ({
       ...prevData,
       preferences: []  
    }));


  const  ischecked = e.target.checked
  const prefBoxes = document.querySelectorAll('.prefBox input');
  prefBoxes.forEach((box) => {
    if(ischecked === true){
      if(box.value != 'none'){
        box.checked = false;
      }
    }

  });
 }else{
   const  ischecked = e.target.checked
  const prefBoxes = document.querySelectorAll('.prefBox input');
  prefBoxes.forEach((box) => {
    if(ischecked === true){
      if(box.value === 'none'){
        box.checked = false;
      }
    }

  });
 }
    
      if(formData.preferences.includes(e.target.value)){
        
        setFormData((prevData) => ({
       ...prevData,
       preferences: prevData.preferences.filter((preference) => preference !== e.target.value)
        }));
      }else{
      setFormData((prevData) => ({
        ...prevData,
        preferences: [...prevData.preferences, e.target.value],
      }));
    }

    }
      

    const handleNext = () => {
      localStorage.setItem('userdata', JSON.stringify(formData));
      navigate('/meals');
    };

   

    const prefs = ['family friendly','kid friendly','low carb','vegetarian','pescatarian','calorie smart','classics','guilt free','chicken','meat','gourmet']


  return (
    <>
    <div className='wrapper prefs'>
          <div className="container">
        
        


  <h1>Preferences</h1>
  <h2>Select your preferences</h2>
  
     
           <div className="boxesPeople">
      
        <div className="checkboxes-group">
          
           {prefs.map((category, index) => (
              <div key={index} className='prefBox testCheck'>
              <input type="checkbox" value={category}  id={category} name="preferences"  onChange={handleChange} key={category} checked={formData.preferences.includes(category)}  /> <label className={`preference ${category.replace(/ /g,'')}`}   htmlFor={category}>{category}</label> 
              </div>
            ))}

          <div  className='prefBox'>
                <input type="checkbox" value="none" id="none" name="preferences"  onChange={handleChange} className='none'  /><label className="none"   htmlFor="none">None</label>
              </div>
          
       
         </div>
       
        </div>
        {/* <p>Preferences selected = {preferenceList}</p> */}
        <button className="nextButton" onClick={handleNext}>Next</button>

</div>

</div>
   </>
  )
}


export default Preferences
