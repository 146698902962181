import { useEffect, useState, useContext, useRef} from "react";
import { Link } from "react-router-dom";
import axios from 'axios';

const Contact = () => {
     const [inputs, setInputs] = useState({});
      const [textarea, setTextarea] = useState();
      const [formData, setFormData] = useState({});
      const [errormessage, setErrormessage] = useState('');
      const [isChecked, setIsChecked] = useState(false)


      useEffect(() => {
          if(errormessage){
            document.getElementById('error').style.visibility = 'visible'
            document.getElementById('error').style.display = 'block'
          }
      },[errormessage])
  const handleChange = (event) => {


const fieldName = event.target.name;
    const value = event.target.value
   setFormData({
      ...formData, // Keep existing form data
      [fieldName]: value // Update form data for the input field that changed
    });
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    // console.log(formData);
    if(!formData.fName || !formData.lName || !formData.email || !formData.message || !formData.phone){
        setErrormessage('All fields are required')
        return
    }
    if(!isChecked){
        console.log(isChecked)
        setErrormessage('Please accept the privacy policy')
        return
    }
     try {
      await axios.post('/api/auth/send', formData);
      alert('Email sent successfully!');
    } catch (error) {
      console.error('Error sending email', error);
      alert('Failed to send email.');
    }
  }
  return (
   <>
<div className="containerForm">
    <div className='left'>
        <div className="leftContents">
	<h1 className="title">Talk to Us</h1>
    <h2>Need help? Got a question?</h2>
<p>
Contact us on <strong>(04) 123 456</strong>, and our friendly support team will be happy to help you.
</p>
<p>
Our phone lines are available daily from 7 am to 8 pm. </p>
<p>
    Otherwise, please use the form below to send us a message.
</p>
<p className="socials">Or contact us on social media</p>
<div className="socialImgs"><object className="newSVG" type="image/svg+xml" data="/images/instagram-167-svgrepo-com.svg" alt="instagram" /><object className="newSVG" type="image/svg+xml" data="/images/X_logo_2023_original.svg" alt="x" /><object className="newSVG" type="image/svg+xml" data="/images/facebook-176-svgrepo-com.svg" alt="facebook" /></div>
<svg fill="#5d693b" version="1.1" id="XMLID_276_"  viewBox="0 0 24.00 24.00" width="256px" height="256px" stroke="#5d693b" strokeWidth="0.00024000000000000003"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" stroke="#CCCCCC" strokeWidth="0.43200000000000005"></g><g id="SVGRepo_iconCarrier"> <g id="contact-us"> <g> <path d="M4,24v-5H0V0h23v19h-9.3L4,24z M2,17h4v3.7l7.3-3.7H21V2H2V17z"></path> </g> <g> <rect x="5" y="8" width="3" height="3"></rect> </g> <g> <rect x="10" y="8" width="3" height="3"></rect> </g> <g> <rect x="15" y="8" width="3" height="3"></rect> </g> </g> </g></svg>
	</div>
    </div>
    <div className='right'>
	<form onSubmit={handleSubmit}>
		

			{/* <!-- Name --> */}
			<div className="form-group position-relative">
				
				<input onChange={handleChange} value={formData.fName || ""} type="text" id="fName" name='fName' className="form-control form-control-lg thick" placeholder="First Name"  />
			</div>
            <div className="form-group position-relative">
				
				<input onChange={handleChange} type="text" value={formData.lName || ""} id="lName" name='lName' className="form-control form-control-lg thick" placeholder=" Last Name"  />
			</div>

			{/* <!-- E-mail --> */}
			<div className="form-group position-relative">
				<label htmlFor="formEmail" className="d-block">
					
				</label>
				<input onChange={handleChange} type="email" value={formData.email || ""} id="formEmail" name='email' className="form-control form-control-lg thick" placeholder="E-mail" />
			</div>

            {/* <!-- Phone --> */}
			<div className="form-group position-relative">
				
				<input onChange={handleChange} type="phone" id="phone" value={formData.phone || ""} name='phone' className="form-control form-control-lg thick" placeholder="Phone number" />
			</div>

			{/* <!-- Message --> */}
			<div className="form-group message">
				<textarea onChange={handleChange} id="formMessage" value={formData.message || ""}  name='message' className="form-control form-control-lg" rows="7" placeholder="Message"></textarea>
			</div>

            <div className="form-group message privacy">
				<input id="privacy" name='privacy' className="form-control form-control-lg" checked={isChecked}  onChange={() => setIsChecked(!isChecked)}   type='checkbox'/><label>I agree to Cookit's <Link to="/privacy">Privacy Policy</Link> </label>
			</div>
            <div><p id="error" className="error">{errormessage}</p></div>
			{/* <!-- Submit btn --> */}
			<div className="formbutton">
				<button type="submit" className="btn btn-primary" tabIndex="-1">Send message</button>
			</div>
	</form>
    </div>
	
</div>


   </>
  )
}

export default Contact