import React, { useEffect, useState } from 'react';
import '../styles/daterange.css'

/**
 * Returns the most recent past Sunday (or today if it's Sunday) as a Date object.
 *
 * This function first gets the current date using the Date constructor.
 * Then, it gets the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday) using the getDay method.
 * If the current day is not Sunday, it calculates the date of the last Sunday by subtracting the day of the week from the current date.
 * Finally, it returns the resulting Date object.
 */
function getLastSunday() {



var now = new Date();
var today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
var lastSunday = new Date(today.setDate(today.getDate()-today.getDay()));
const offset = lastSunday.getTimezoneOffset()
lastSunday = new Date(lastSunday.getTime() - (offset*60*1000))
// lastSunday = lastSunday.toISOString().split('T')[0]

   
    return lastSunday;
}


/**
 * Returns an array of Date objects representing the Sundays for the next 52 weeks from the most recent past Sunday (or today if it's Sunday).
 *
 * The function starts by getting the date of the most recent past Sunday using the getLastSunday function.
 * Then, it iterates over a range of 52 weeks, and for each week, it creates a new Date object representing the current Sunday.
 * The current Sunday is calculated by adding the number of days in a week (7) multiplied by the current week number to the date of the last Sunday.
 * The resulting array of Sundays is then returned.
 */
function getSundaysForNext52Weeks() {
    let sundays = [];
    let lastSunday = getLastSunday();

    // Iterate over a range of 52 weeks
    for (let i = 0; i < 52; i++) {
        // Create a new Date object representing the current Sunday
        let currentSunday = new Date(lastSunday);
        // Calculate the date of the current Sunday by adding the number of days in a week (7) multiplied by the current week number to the date of the last Sunday
        currentSunday.setDate(lastSunday.getDate() + (i * 7));
        // Add the current Sunday to the array of Sundays
        sundays.push(currentSunday);
    }

    // Return the array of Sundays
    return sundays;
}



/**
 * Formats a given date as a string in the format "Month Day" (e.g., "January 1")
 * using the locale of the user's browser.
 *
 * @param {Date} date - The date to be formatted.
 * @returns {string} The formatted date string.
 */
function formatDate(date) {
    // Create an options object with the day property set to 'numeric'.
    // This tells the Date object to format the date as a numeric day of the month (1-31),
    // rather than as a weekday name or abbreviated weekday name.
    const options = {day: 'numeric'};

    // Use the Date object's toLocaleDateString method to format the date as a string.
    // The method takes two arguments: the first is the locale to use for formatting (in this case, undefined),
    // and the second is an options object that specifies how to format the date.
    return date.toLocaleDateString(undefined, options);
}

/**
 * Returns the index of the current week in the array of Sundays returned by getSundaysForNext52Weeks.
 * If the current date is not within the range of the next 52 Sundays, the function returns -1.
 *
 * @param {Date[]} sundays - The array of Sundays returned by getSundaysForNext52Weeks.
 * @returns {number} The index of the current week in the array of Sundays, or -1 if the current date is not within the range.
 */
function getCurrentWeekIndex(sundays) {
    let today = new Date();

    // Iterate over the array of Sundays
    for (let i = 0; i < sundays.length; i++) {
        // Get the next Sunday after the current Sunday
        let nextSunday = new Date(sundays[i]);
        nextSunday.setDate(sundays[i].getDate() + 7);

        // Set the time of the next Sunday to midnight
        // nextSunday.setHours(0, 0, 0, 0);

        // Check if the current date is within the range of the current Sunday and the next Sunday
        if (today >= sundays[i] && today < nextSunday) {
            // If it is, return the index of the current Sunday
            return i;
        }
    }

    // If the current date is not within the range of the next 52 Sundays, return -1
    return -1;
}

/**
 * Returns a string representing the months covered by a date range.
 * If the range covers only one month, the function returns the name of that month.
 * If the range covers more than one month, the function returns a string in the format "Month1 - Month2"
 *
 * @param {Date} start - The start date of the range.
 * @param {Date} end - The end date of the range.
 * @returns {string} A string representing the months covered by the range.
 */
function getMonthsInRange(start, end) {
    let startMonth = start.toLocaleString('default', { month: 'long' });
    let endMonth = end.toLocaleString('default', { month: 'long' });

    if (startMonth === endMonth) {
        return startMonth;
    } else {
        return `${startMonth} - ${endMonth}`;
    }
}

const WeeklyDateRanges = ({ onRequest, passedIndex, calRange }) => {

   const dispRange = calRange || 4
    // State variables to hold the list of week ranges, the currently selected ISO date, and the index of the currently selected week range
    const [weekRanges, setWeekRanges] = useState([]);
    const [selectedIsoDate, setSelectedIsoDate] = useState('');
    const [selectedIndex, setSelectedIndex] = useState(0);
   
   
    // useEffect hook to run when the component is mounted
    useEffect(() => {
        //setSelectedIndex(passedIndex || 0)
        // Get the next 52 Sundays from the last Sunday
        let sundays = getSundaysForNext52Weeks();

        // Get the current week index based on today's date
        let currentWeekIndex = getCurrentWeekIndex(sundays);

        // If the current week index is not -1 (i.e., the current date is within the range of the next 52 Sundays),
        // then calculate the week ranges for the next 4 weeks and set the state accordingly
        if (currentWeekIndex !== -1) {
            let ranges = sundays.slice(currentWeekIndex, currentWeekIndex + dispRange).map((sunday, index) => {
                // For each Sunday, get the corresponding Saturday
                let saturday = new Date(sunday);
                saturday.setDate(sunday.getDate() + 6);

                // Return an object with the week number, start date, end date, months covered, and ISO date
                return {
                    week: currentWeekIndex + index + 1,
                    start: formatDate(sunday),
                    end: formatDate(saturday),
                    months: getMonthsInRange(sunday, saturday),
                    isoDate: sunday.toISOString().split('T')[0] // ISO date format (YYYY-MM-DD)
                };
                

            });
            setWeekRanges(ranges);
        }
    }, [passedIndex]);

    // Function to handle clicks on the week range list items
    const handleClick = (index,isoDate) => {
        
       // console.log(index)
        
        // Set the state to the newly selected week range index and ISO date
        setSelectedIndex(index);
        setSelectedIsoDate(isoDate);

        
        // Call the onRequest callback function with the newly selected ISO date
        onRequest(isoDate,index);
    };

    // useEffect(() => {
    //     onRequest(selectedIsoDate,selectedIndex);
    // },[selectedIsoDate,selectedIndex])
    
    // JSX to render the component
    return (
        <div className="dateRange">
            {/* // If the selected ISO date is not empty, render a paragraph with the selected ISO date */}
            
            <ul>
                {/* // Map over the week ranges and render a list item for each one */}
                {weekRanges.map((range, index) => (
                    <li key={range.isoDate} className={selectedIndex === index ? 'selected' : ''} onClick={() => {handleClick(index, range.isoDate)}}>
                        <span>{range.start} - {range.end} </span> <br />
                        {range.months}
                    </li>
                    
                ))}
            </ul>
        </div>
    );
};

export default WeeklyDateRanges;