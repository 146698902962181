import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from "axios" 
import '../styles/register.css'
import '../styles/signup.css'

export const Signup = ({formData, setFormData, inRegister, setInRegister} ) => {
  // console.log(formData)
  const[email, setEmail] = useState(formData.email)
  const[password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [ user, setUser ] = useState([]);
  const [ profile, setProfile ] = useState([]);
  const navigate = useNavigate();
 useEffect(() => {
       if(!inRegister.includes('3')){
            navigate('/boxes')
          }
          
           setInRegister(['0','1','2','3','4'])
    },[])
  const handleChange = (e) => {
     const { name, value } = e.target;
     name === 'email' ? setEmail(value) : setPassword(value)
    
      setFormData((prevData) => ({
        ...prevData,
        [name]: value
      }));
    };
 

// console.log(formData)


    function validateEmail(email) {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(String(email).toLowerCase().trim());
}


 const handleSubmit = async(e) => {
  e.preventDefault()
  if(!email || !password){
    setErrorMessage(`Email and Password are required`)
    document.querySelector('.error').style.display = 'block';
  }else if(!validateEmail(email)) {
    setErrorMessage(`Invalid Email Format`);
   document.querySelector('.error').style.display = 'block';
  }else{
    await axios.get(`/api/auth/checkusers/${email}`)
    .then(res=>{
      if(res.data.exists){
        setErrorMessage(`Sorry but that email address is already being used.`)
        document.querySelector('.error').style.display = 'block';
      }else{
        navigate('/delivery');
      }
    })
    
  }
  

 }


  return (
    <div className="containerForm">
    <div className='left'>
        <div className="leftContents">
	<h1 className="title">Sign Up</h1>
    <h2>Set up your login details</h2>
<p>Set up your email and passowrd to login to our site</p>

<svg width="256px" height="256px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="#5d693b" transform="rotate(0)"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <title>i</title> <g id="Complete"> <g id="user-add"> <g> <path d="M17,21V19a4,4,0,0,0-4-4H5a4,4,0,0,0-4,4v2" fill="none" stroke="#5d693b" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"></path> <circle cx="9" cy="7" r="4" fill="none" stroke="#5d693b" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"></circle> <line x1="17" y1="11" x2="23" y2="11" fill="none" stroke="#5d693b" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"></line> <line x1="20" y1="8" x2="20" y2="14" fill="none" stroke="#5d693b" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"></line> </g> </g> </g> </g></svg>	</div>
         </div>
   
    <div className='right'>
            <form className="signUpForm">
              <div>
              <input type="email" placeholder="Email address" id="email" name="email" value={email} onChange={handleChange} required></input>
              </div>
              <div>
              <input type="password" placeholder="password" id="password" name="password" value={password}  onChange={handleChange} required></input>
              </div>
              <p className="error">{errorMessage}</p>
			    
          <div className="formbutton">
              <button type="submit" onClick={handleSubmit}>Submit</button>
              </div>
            </form>
            </div>
</div>
          

        // {/* </div> */}
        //  {/* </div> */}
        
  )
}

export default Signup