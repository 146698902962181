import { useLocation,useNavigate,Link } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import '../styles/meals.css'
import axios from "axios" 
import WeeklyDateRanges from "./weeklydaterange";


export default function Themeals(){

 

const [MealsList, setMealsList] = useState([])
const [filteredMealsList, setFilteredMealsList ] = useState([])
// const [filteritems, setFilteritems] = useState([])
// const [filterItem, setFilterItem] = useState('All')
// const [dateused, setDateused] = useState('')
// const [data, setData] = useState(null);
 const [selectedMealId, setSelectedMealId] = useState(null);
// const [ tryfilter, setTryfilter] = useState('')


 const ref = useRef()

 const [selectedFilters, setSelectedFilters] = useState([]);


const filters = ['family friendly','kid friendly','low carb','vegetarian','pescatarian','calorie smart','classic','guilt free','chicken','meat','gourmet','clear filter']




 
useEffect(() => {

var now = new Date();
var today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
var lastSunday = new Date(today.setDate(today.getDate()-today.getDay()));
const offset = lastSunday.getTimezoneOffset()
lastSunday = new Date(lastSunday.getTime() - (offset*60*1000))
lastSunday = lastSunday.toISOString().split('T')[0]

handleGetRequest(lastSunday)

}, []);

useEffect(() => {
   filterItems();
   
}, [selectedFilters, filteredMealsList]);


  const handleFilter = (selectedCategory) => {
    
    if(selectedCategory === 'clear filter'){
     
      setSelectedFilters([]);
    }else{
if (selectedFilters.includes(selectedCategory)) {
      let filters = selectedFilters.filter((el) => el !== selectedCategory);
      setSelectedFilters(filters);
      //  console.log(selectedFilters)
    } else {
      setSelectedFilters([...selectedFilters, selectedCategory]);
    }
    }
    
    }   



   
  
const filterItems = () => {

    if (selectedFilters.length > 0) {
      let tempItems = selectedFilters.map((selectedCategory) => {
       
        let temp = filteredMealsList.filter((item) => item.mealtype.some(type => type.includes(selectedCategory)));
         console.log(filteredMealsList)
        return temp;
      });

     
      const finaltempItems = tempItems.flat().reduce((acc, current) => {
        const index = acc.findIndex((item) => item._id.includes(current._id));
      
        if (index === -1) {
          acc.push(current);
        }
        return acc;
      }, []);

      setMealsList(finaltempItems.flat());
     
    } else {
      setMealsList(filteredMealsList);
    }
  };

const handleGetRequest = async (lastSunday) => {

    try {
      const response = await axios.get(`/api/auth/newMeals`, {
        params: {
          dateUsed: lastSunday
        }
      })
       .then(res=>{
       
          const mealsData = res.data.mergedResults
          
          setMealsList(mealsData)
           setFilteredMealsList(mealsData)
         
         
        })
      }catch(error){
        console.log(error)
    
    }
  };




const filterRef = useRef();
function toggleDialog() {
  
  if(!filterRef.current){
    return
  }
  filterRef.current.hasAttribute("open") ?  document.body.classList.remove("hideOverflow") : document.body.classList.add("hideOverflow");
 
  filterRef.current.hasAttribute("open") ? filterRef.current.close() : filterRef.current.showModal();
 
}



  return(
    <>
    <div className=" container  themeals">

      <h1>Our Meals</h1>
    <div className="datesContainer">
    
    <WeeklyDateRanges  onRequest={handleGetRequest} />
    
    
    </div>
    {/* <div className="filterContainer"><img src="/images/icons/filter.png" className="filterIcon" alt="filter" onClick={toggleDialog} />
    </div> */}
    <div className="filterContainer">
    <ul className="filters" >
            {filters.map((category, idx) => (
              <li className={`filterbutton ${category.replace(/ /g,'')} ${selectedFilters?.includes(category) ? "active" : ""} `} key={`filters-${idx}`} onClick={() => handleFilter(category)}>{category}</li>
            ))}
              
            </ul>
     </div>
    <div className="mealList">
        {
        
            MealsList.map( meal => {
              return <div  key={meal._id} id={meal._id} className="mealCard" onClick={() => {
        setSelectedMealId(meal._id);
       
      }}>
        
          <div className="imgContainer">
              <img src={`/images/meals/${meal.image}`} alt="image" />
          </div>
         
           <div className='contentContainer'>
                          <div className="mealDetails">
                              <h4>{meal.title}</h4>
                              <p>{meal.subtitle}</p>
                          </div>
        
        <div className="mealtypes">       
                              <ul>
                              {meal.mealtype.toString().split(',').map((type, index) => (
                                <li key={index} className={type.replace(/ /g,'')}>{type}</li>
                              ))
                              }

                              </ul>
                          </div> 
                
                

              </div>
              </div>
            })

        }

        </div>
      </div>

       
 {/* <dialog className="filter" ref={filterRef} id="filterref">
  <div className="modalTop"><span>Filters</span><button className="close" onClick={toggleDialog}>X</button></div>
<div className="modalBody"> 
            <ul className="filters" >
            {filters.map((category, idx) => (
              <li className={`filterbutton ${category.replace(/ /g,'')} ${selectedFilters?.includes(category) ? "active" : ""} `} key={`filters-${idx}`} onClick={() => handleFilter(category)}>{category}</li>
            ))}
              
            </ul>
            </div>
            </dialog> */}
     </>

     

      
 
    )
 


}