import React, { useEffect, useState,useRef, useContext } from 'react';
import { useLocation,useNavigate,Lin } from "react-router-dom";
import axios from "axios" 
import { AuthContext } from '../context/AuthContext';
import { getWeeklyRangesUntil, getCurrentWeekStartEnd } from './dateUtility';
import UserPanel from "./userPanel";

const Mydelivery = (props) => {
     const [updateMessage, setUpdateMessage] = useState('')
const [loading, setLoading] = useState(false);
const [weeklyRanges, setWeeklyRanges] = useState([]);
const { authState } = useContext(AuthContext);
const [formData, setFormData] = useState({
    deliveryDay: '',
    deliverTimeSlot: '',
    PaymentDay: '',
    skipWeek: ''
})
const [delday, setDelday] = useState(authState.user.deliveryDay);
const [delslot, setDelslot] = useState(authState.user.deliverTimeSlot);
const [tdate, setTdate] = useState('');
const [selectedIndex, setSelectedIndex] = useState('');
const [paymentDate, setPaymentDate] = useState('');
const [skipweekDate, setSkipweekDate] = useState('');
const [skippedweek, setSkippedweek] = useState();

// console.log(authState)
const userId= authState.user._id
const endDateString = authState.user.paymentDay
//console.log(authState.user)
const skipweekDateD =  authState.user.skipWeek

const endDate = new Date(endDateString);


 useEffect(() => {
   document.title = 'My Delivery details'
   const { startdate, endate } = getCurrentWeekStartEnd();
    const ranges = getWeeklyRangesUntil(startdate, endate);
    setWeeklyRanges(ranges);
  }, []);


  const dialogRef = useRef();
const toggleDialog = async function(id) {

  if(!dialogRef.current){
    return
  } 
  dialogRef.current.hasAttribute("open") ? dialogRef.current.classList.add("closing") : dialogRef.current.classList.remove("closing");
  dialogRef.current.hasAttribute("open") ?  document.body.classList.remove("hideOverflow") : document.body.classList.add("hideOverflow");
   dialogRef.current.hasAttribute("open") ? dialogRef.current.close()  : dialogRef.current.showModal();

   if(dialogRef.current.hasAttribute("open")){
    

  }

}
  

const handleDay = (e) => {
  const selectedDay = e.target.getAttribute('value');

   setFormData((prevData) => ({
        ...prevData,
        deliveryDay: selectedDay
      }));
  
  //const selectedSlot = slotSetter[delslot]; // Assuming slotsetter is an object with slots for each day


  setDelday(selectedDay);
 
}

const handledelSlot = (e) =>{
  setDelslot(e.target.getAttribute('value'));
  


   setFormData((prevData) => ({
        ...prevData,
        deliverTimeSlot: e.target.getAttribute('value')
      }));
 
}

useEffect(() =>{
    
   
     switch(delday){
      case "Monday":
      var d = new Date();
      d.setDate(d.getDate() + (((1 + 7 - d.getDay()) % 7) || 7));
      setTdate(d.toString().slice(4,10))
     
      break;
       case "Tuesday":
        
      var d = new Date();
      d.setDate(d.getDate() + (((1 + 8 - d.getDay()) % 7) || 7));
       setTdate(d.toString().slice(4,10));
       
      break;
       case "Wednesday":
      var d = new Date();
      d.setDate(d.getDate() + (((1 + 9 - d.getDay()) % 7) || 7));
     setTdate(d.toString().slice(4,10));
      break;
       case "Thursday":
      var d = new Date();
      d.setDate(d.getDate() + (((1 + 10 - d.getDay()) % 7) || 7));
     setTdate(d.toString().slice(4,10));
      break;
       case "Friday":
      var d = new Date();
      d.setDate(d.getDate() + (((1 + 11 - d.getDay()) % 7) || 7));
      setTdate(d.toString().slice(4,10));
      break;
    }

//  props.messageSet('plansettings')
},[delday])

 const handleClick = async(e) => {
  toggleDialog();
  setLoading(true);
 
       try {                         
      const response = await axios.put(`/api/auth/updateUserDelivery/${userId}`, {
        deliveryDay:delday,
        deliverTimeSlot:delslot,
        paymentDay: paymentDate,
        skipWeek: skippedweek
      })
       .then(response=>{ 
    
         
         
    setUpdateMessage(response.data.message)

    setLoading(false)
        
      
      })
      
       
         }catch(error){
        console.error(`Error updating meal selections:`, error);
    
    }
    
       }
    const handleSkipweek = async(index,isoDate) => {
     if( document.querySelector('.selected')) {
      document.querySelector('.selected').classList.remove('selected')
     }
   // console.log(index, isoDate)
      
      setSelectedIndex(index);

      // const dateObj = new Date(endDate);
      // console.log(isoDate)
    
      // dateObj.setDate(dateObj.getDate() );

    // Convert the modified date back to ISO string
     // const newPaymentDate = dateObj.toISOString();
      
      //   setPaymentDate(newPaymentDate);
         setSkippedweek(isoDate);

      //   setFormData((prevData) => ({
      //   ...prevData,
      //   paymentDay: newPaymentDate,
      //   skipWeek: isoDate
      // }));
    
  }


  return (
    <>
     <div className="dashboardContainer">
          
           <div className="userContainer">
                {/* <div className="userLinks">
                <UserPanel />
                </div> */}

                <div className="userData"> 
  <div className="boxes wrapper">
  <div className="container ">
    <h1>Delivery</h1>
    <h2>Edit your delivery</h2>

    <div className="deliveryPanels delivery">    
      <div className='deliveryPanel'>
      <h3>Select your preffered delivery day</h3>
        <ul>
            <li  value="Monday" className={delday === "Monday" ? "active" : "" } onClick={handleDay}>Monday</li>
            <li  value="Tuesday" className={delday === "Tuesday" ? "active" : "" }  onClick={handleDay}>Tuesday</li>
            <li  value="Wednesday" className={delday === "Wednesday" ? "active" : "" }    onClick={handleDay}>Wednesday</li>
            <li  value="Thursday" className={delday === "Thursday" ? "active" : "" }  onClick={handleDay}>Thursday</li>
            <li  value="Friday" className={delday === "Friday" ? "active" : "" }  onClick={handleDay}>Friday</li>
        </ul>
        
      <h3> Select your preffered delivery time slot</h3>
        <h4> Morning</h4>
        <ul>
            <li value="1" className={delslot === "1" ? "active" : "" }  onClick={handledelSlot}>6-9am</li>
            <li value="2" className={delslot === "2" ? "active" : "" }  onClick={handledelSlot}>9-12am</li>
        </ul>

        <h4> Afternoon</h4>
        <ul>
            <li value="3" className={delslot === "3" ? "active" : "" } onClick={handledelSlot}>12-3pm</li>
            <li value="4" className={delslot === "4" ? "active" : "" }  onClick={handledelSlot}>3-6pm</li>
        </ul>

        <h4> Evening</h4>
        <ul>
            <li value="5" className={delslot === "5" ? "active" : "" }  onClick={handledelSlot}>6-10pm</li>
          
        </ul>
      </div>
      <div className='deliveryPanel'>
        <h3>Skip a week</h3>
<div>
  <div className="dateRange"><ul>
 
      {weeklyRanges.map((range, index) => (
        <li key={range.start} className={(selectedIndex === index) ? 'selected' : ''  } onClick={() => {handleSkipweek(index, range.start.toISOString().split('T', 1)[0] )}}>{range.formattedRange}</li>
      ))}
      </ul></div>
    </div>
      </div>
      </div>
       <div className="saveButtons">
             <button className="nextButton" onClick={handleClick}>Save</button>
        </div>
      </div>
      </div>
      </div>
      </div>
</div>
<dialog className="filter"  ref={dialogRef} id="PaymentRef">
  <div className="modalTop"><span>Processing payment</span><button className="close" onClick={toggleDialog}>X</button></div>
<div className="modalBody"> 
           <p>Update Meals</p>
              {loading ? <img src="../images/icons/loading.gif" alt="loading" className="loading" /> : <p>{updateMessage}</p>}    
            </div>

             
            </dialog>
      </>
  )
}

export default Mydelivery